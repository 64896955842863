// function to get loading spinner

function LoadingIndicator() {
  return (
    <div className="loading pt-5 mx-5">
              <div className="spinner-box mb-3 mx-5">
                Loading
                <div className="pulse-container ms-3">
                  <div className="pulse-bubble pulse-bubble-1"></div>
                  <div className="pulse-bubble pulse-bubble-2"></div>
                  <div className="pulse-bubble pulse-bubble-3"></div>
                </div>
              </div>
            </div>
  )
}

export default LoadingIndicator