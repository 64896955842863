import React from "react";
import { useState } from "react";
import { getAuthToken } from "../../../utils/token.utils";
import { Link } from "react-router-dom";
import LogInModal from "../../../components/LogInModal";
import ConsentForm from "../consentForm/consentform";

function App() {
  const [show, setShow] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [showConsentForm, setShowConsentForm] = useState(false);

  const handleClose = () => setShow(false);
  const handleCloseConsent = () => setShowConsent(false);
  const handleShow = () => setShow(true);

  const handleShowConsentForm = () => {
    setShowConsentForm(true);
    setShowConsent(true);
  };
  const handleConsentSubmit = () => {
    setShowConsentForm(false);
    setShow(true);
  };

  const token = getAuthToken();
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="bannerWrap">
                <h1>
                  Welcome to <strong>Valmiki!</strong>{" "}
                </h1>
                <p>
                  Tired of document drudgery? <br /> We make documents and
                  presentations so that you don't have to!
                </p>
                {token ? (
                  <Link to="/chat">
                    <button className="univ-btn-light">
                      Start Conversation
                    </button>
                  </Link>
                ) : (
                  <>
                    {" "}
                    <button
                      className="univ-btn-light"
                      onClick={handleShowConsentForm}
                    >
                      Get Started
                    </button>
                    {showConsentForm && (
                      <ConsentForm
                        show={showConsent}
                        handleCloseConsent={handleCloseConsent}
                        onSubmit={handleConsentSubmit}
                      />
                    )}
                    <LogInModal
                      show={show}
                      handleClose={handleClose}
                      handleShow={handleShow}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
