
import { useState , useEffect} from 'react';
import { Auth } from 'aws-amplify';

const userPoolWebClientId = process.env.REACT_APP_AUTH_USER_POOL_ID;
const LastAuthUser =localStorage.getItem(`CognitoIdentityServiceProvider.${userPoolWebClientId}.LastAuthUser`
)

export const useAuthToken = () => {
  const [accessToken, setAccessToken] = useState<string | null>(null);

  useEffect(() => {
    const fetchAuthToken = async () => {
        try {
            const session = await Auth.currentSession();
            const accessToken = session.getAccessToken();
            const refreshToken = session.getRefreshToken();
            setAccessToken(accessToken.getJwtToken());
          
        } catch (error) {
            console.error('Error fetching current session:', error);
        }
    };

    fetchAuthToken();
}, []); 


  return accessToken;
};


export const getAuthToken = () => {
  const token = localStorage.getItem(`token`)
  return token;
};

export const getRefreshToken = () =>{
  const refreshToken =  localStorage.getItem(`refreshtoken`)
  return refreshToken
}









