import { useState, useEffect } from "react";

import api from "../../utils/axiosInstance";
import UploadModal from "../../components/UploadModal";

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";

import UploadedGeneratedModal from "./partials/UploadedGeneratedModal";
import LoadingIndicator from "./partials/LoadingIndicator";

import { LazyLoadedImage } from "./partials/LazyLoadedImage";
import {
  deleteGeneratedTemplate,
  generateUrl,
  getAllGeneratedTemplates,
  getUploadedTemplates,
} from "./partials/apiservice";
import ModalComponent from "./partials/GeneratedModalComponent";

interface Document {
  preview: string;
  doc_id: string;
  gallery_type: string;
  doc_title: string;
}

const Documents = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const ID_Token = localStorage.getItem("idtoken") || "";
  const [uploadeddata, setUploadedData] = useState<Document[]>([]);
  const [Generateddata, setGeneratedData] = useState<Document[]>([]);
  const [docloading, setDocLoading] = useState(false);
  const [nestedArray, setNestedArray] = useState<string[]>([]);
  const [docId, setDocId] = useState("");
  const [modalLoading, setModalLoading] = useState(true);
  const [showdocumentModal, setShowdocumentModal] = useState(false);
  const [pdflink, setPdfLink] = useState<string | string[]>([]);
  const [doclink, setDocLink] = useState<string | string[]>([]);
  const fetchUploadedDocument = async () => {
    try {
      const data = await getUploadedTemplates(ID_Token);
      setUploadedData(data);
      setDocLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setDocLoading(false);
    }
  };

  useEffect(() => {
    setDocLoading(true);
    fetchUploadedDocument();
  }, []);
  const fetchGeneratedData = async () => {
    try {
      const data = await getAllGeneratedTemplates(ID_Token);

      setGeneratedData(data);
      setDocLoading(false);
    } catch (error) {
      console.error("Error:", error);
      setDocLoading(false);
    }
  };
  useEffect(() => {
    setDocLoading(true);

    fetchGeneratedData();
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const closeModal = () => {
    setSelectedImage(null);
    setShowModal(false);
    setNestedArray([]);
    setCurrentIndex(0);
    setShowdocumentModal(false);
  };

  const handlegeneratedtemplatepreview = async (doc_id: any, doctype: any , ID_Token : any) => {
    setShowModal(true);
    setModalLoading(true);
    try {
      const data = await generateUrl(doc_id, doctype , ID_Token);
      setNestedArray(data.image);
      setDocId(doc_id);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setModalLoading(false); // Set modal loading to false when data fetching is complete
    }
  };
  const handledocumentgeneratedtemplatepreview = async (
    doc_id: any,
    doctype: any
  ) => {
    setShowdocumentModal(true);
    setDeletedDocId(doc_id);
    setModalLoading(true);
    try {
      const data = await generateUrl(doc_id, doctype , ID_Token);
      setNestedArray(data.image);
      setDocId(doc_id);

      setDocLink(data.pdf);
      setPdfLink(data.doc);
    } catch (error) {
      console.error("Error fetching categories:", error);
    } finally {
      setModalLoading(false); // Set modal loading to false when data fetching is complete
    }
  };

  const handleDownload = (format: string) => {
    if (format === "pdf") {
      window.open(pdflink as string, "_blank");
    } else if (format === "doc") {
      window.open(doclink as string, "_blank");
    }
  };

  const [completedPracticeSet, setCompletedPracticeSet] = useState([]);
  const [completedRealSet, setCompletedRealSet] = useState([]);

  const [postsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const [activeTab, setActiveTab] = useState(
    localStorage.getItem("activeTab") || "practice"
  );

  const handleTabClick = (tab: any) => {
    setActiveTab(tab);
    localStorage.setItem("activeTab", tab);
  };

  const filteredSets =
    activeTab === "practice" ? completedPracticeSet : completedRealSet;
  const indexOfLastSet = currentPage * postsPerPage;
  const indexOfFirstSet = indexOfLastSet - postsPerPage;
  const currentSets = Object.values(filteredSets).slice(
    indexOfFirstSet,
    indexOfLastSet
  );

  // console.log(uploadeddata);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const deleteDocument = async (docId: any) => {
    // Show SweetAlert confirmation
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this document!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#dc3545", // Red color for the confirm button
      cancelButtonText: "No, keep it",
    });

    // If user confirms deletion
    if (result.isConfirmed) {
      try {
        const response = await api(
          `api/gallery/delete-uploaded-template/?doc_id=${docId}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // You may need to include any necessary authentication headers here
            },
          }
        );

        if (response) {
          // Show success message with SweetAlert
          Swal.fire({
            title: "Deleted!",
            text: "Your document has been deleted.",
            icon: "success",
            confirmButtonColor: "#1c6176",
          }).then(() => {
            // Close modal and navigate after SweetAlert confirmation
            setDocLoading(true);

            fetchUploadedDocument();

            closeModal();
            navigate(`/documents`);
          });
        } else {
          throw new Error("Failed to delete document");
        }
      } catch (error) {
        console.log(error);
        // Show error message with SweetAlert
        Swal.fire(
          "Error!",
          "Failed to delete document. Please try again later.",
          "error"
        );
      }
    }
  };

  const generateddocument = async (docId: any) => {
    navigate(`/generateddocument/${docId}`);
  };
  const [deleteddocId, setDeletedDocId] = useState("");
  const deletegenerateddocument = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this document!",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      confirmButtonColor: "#dc3545",
      cancelButtonText: "No, keep it",
    });

    if (result.isConfirmed) {
      try {
        await deleteGeneratedTemplate(deleteddocId, ID_Token);
        Swal.fire({
          title: "Deleted!",
          text: "Your document has been deleted.",
          icon: "success",
          confirmButtonColor: "#1c6176",
        }).then(() => {
          setDocLoading(true);

          fetchGeneratedData();
          setShowdocumentModal(false);
          navigate(`/documents`);
        });
      } catch (error) {
        console.log(error);
        Swal.fire(
          "Error!",
          "Failed to delete document. Please try again later.",
          "error"
        );
      }
    }
  };

  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="bg-white profile-header round shadow-sm mb-4 mob-block py-4 px-4">
          <div className="content-1 round ms-4">
            <>
              <nav className="mt-3">
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  <button
                    className={`nav-link ${
                      activeTab === "practice" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("practice")}
                  >
                    Uploaded Documents
                  </button>
                  <button
                    className={`nav-link ${
                      activeTab === "exam" ? "active" : ""
                    }`}
                    onClick={() => handleTabClick("exam")}
                  >
                    Generated Documents
                  </button>
                </div>
              </nav>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className={`tab-pane fade ${
                    activeTab === "practice" ? "show active" : ""
                  } pt-3`}
                  id="nav-practice"
                >
                  {docloading ? (
                    <LoadingIndicator />
                  ) : uploadeddata.length > 0 ? (
                    <div className="row">
                      {uploadeddata.map((file, index) => (
                        <div
                          className="col-md-6 col-lg-3 mb-4 text-center"
                          key={index}
                        >
                          <LazyLoadedImage
                            src={file.preview}
                            onClick={() =>
                              handlegeneratedtemplatepreview(
                                file.doc_id,
                                file.gallery_type,
                                ID_Token

                              )
                            }
                            alt={file.doc_title}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="mb-3">
                        No uploaded document is currently available. You can
                        upload a template from this page.
                      </div>
                      <UploadModal />
                    </>
                  )}
                </div>
                <div
                  className={`tab-pane fade ${
                    activeTab === "exam" ? "show active" : ""
                  } pt-3`}
                  id="nav-exam"
                >
                  {docloading ? (
                    <LoadingIndicator />
                  ) : Generateddata.length > 0 ? (
                    <div className="row">
                      {Generateddata.map((file, index) => (
                        <div
                          className="col-md-6 col-lg-3 mb-4 text-center"
                          key={index}
                        >
                          <LazyLoadedImage
                            src={file.preview}
                            onClick={() =>
                              handledocumentgeneratedtemplatepreview(
                                file.doc_id,
                                file.gallery_type
                              )
                            }
                            alt={file.doc_title}
                          />
                        </div>
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="mb-3">
                        No document has been generated. Please select a template
                        and proceed with the document generation.
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </div>

      <UploadedGeneratedModal
        showModal={showModal}
        closeModal={closeModal}
        deleteDocument={deleteDocument}
        handlePrev={handlePrev}
        handleNext={handleNext}
        currentIndex={currentIndex}
        nestedArray={nestedArray}
        docId={docId}
        modalLoading={modalLoading}
        generateddocument={generateddocument}
      />
      <ModalComponent
        showModal={showdocumentModal}
        handleClose={() => setShowdocumentModal(false)}
        handleDownload={handleDownload}
        handlePrev={handlePrev}
        handleNext={handleNext}
        currentIndex={currentIndex}
        nestedArray={nestedArray}
        modalLoading={modalLoading}
        pdflink = {pdflink}
        doclink = {doclink}
        deleteDocument={deletegenerateddocument}
      />
    </>
  );
};

export default Documents;
