// DocumentCard.tsx
import React from 'react';
import GeneratedDocument from '../generateddocuments';

interface DocumentCardProps {
  file: GeneratedDocument;
  handleClick: (doc_id: string, gallery_type: string) => void;
}

const DocumentCard: React.FC<DocumentCardProps> = ({ file, handleClick }) => (
  <div className="col-md-6 col-lg-3 mb-4 text-center">
    <img
      src={file.preview}
      alt={file.doc_title}
      className="cate-img pt-2"
      onClick={() => handleClick(file.doc_id, file.gallery_type)}
    />
  </div>
);

export default DocumentCard;
