import React from "react";

interface LoadingProps {
  loading: boolean;
}

const Loading = ({ loading }: LoadingProps) => {
  return (
    <>
      {loading && (
        <div className="loading">
          <div className="spinner-box">
            <div className="pulse-container">
              <div className="pulse-bubble pulse-bubble-1"></div>
              <div className="pulse-bubble pulse-bubble-2"></div>
              <div className="pulse-bubble pulse-bubble-3"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loading;
