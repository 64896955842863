import { Modal } from "react-bootstrap";

interface PreviewModalProps {
  show: boolean;
  apicalledonce: boolean;
  showConfirmationDialog: boolean;
  id: string | null;
  index: string | null;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  fileTypeResponse: string | any[];
  handleItemClick: (id: string, index: string) => Promise<void>;
  setShowConfirmationDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setDocShow: React.Dispatch<React.SetStateAction<boolean>>;
  showDoc: boolean;
  handleConfirmSwitchTemplate: (id: string | null) => Promise<void>;
  handleCancelSwitchTemplate: () => void;
  selectedItemIndex: string | null;
  onclose: () => void;
}

const PreviewModal = ({
  setDocShow,
  setShowConfirmationDialog,
  apicalledonce,
  id,
  index,
  show,
  onclose,
  setLoading,

  fileTypeResponse,
  handleItemClick,
}: PreviewModalProps) => {
  // Ensure fileTypeResponse is an array
  const files = Array.isArray(fileTypeResponse)
    ? fileTypeResponse.filter((file) => file.doc_id === index)
    : [];

  return (
    <div>
      <Modal
        show={show}
        onHide={onclose}
        // onHide={onClose}
        centered
        className="PreviewModal"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          {files.map((file, index) => (
            <h3 key={index} style={{ textDecoration: "none" }}>
              {file.doc_title}
            </h3>
          ))}
        </Modal.Header>
        <Modal.Body className="previewmodal">
          <div className="authLogin">
            <ul className="file-list">
              {files.map((file, index) => (
                <li key={index} className="file-item">
                  <a
                   
                    rel="noopener noreferrer"
                  >
                    <img src={file.preview} alt="" className="full-width" />
                  </a>
                </li>
              ))}
            </ul>

            <div className="form-group text-center">
              <button
                className="univ-btn w-100 py-2"
                onClick={() => {
                  if (id !== null && index !== null) {
                    handleItemClick(id, index);
                    setDocShow(false);
                    setLoading(true);
                  } else {
                    console.error("ID or index is null");
                  }
                }}
              >
                Choose this template
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PreviewModal;
