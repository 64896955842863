// components/LazyLoadedImage.js
import { useState, useEffect } from "react";
import PreviewImage from "../../../assets/images/preview.jpg"

interface LazyLoadedImageProps {
    src: string;
    alt: string;
    onClick?: () => void; 
  }
  
 export const LazyLoadedImage: React.FC<LazyLoadedImageProps> = ({
    src,
    alt,
    onClick,
  }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
  
    useEffect(() => {
      let img = new Image();
      img.src = src;
      img.onload = () => {
        setImageSrc(src);
      };
      return () => {
        img.onload = null;
      };
    }, [src]);
  
    return imageSrc ? (
      <img src={imageSrc} alt={alt} className="cate-img pt-2" onClick={onClick} /> 
    ) : (
      <img
        src={PreviewImage}
        alt="uploaded document"
        className="cate-img pt-2"
        onClick={onClick}
      /> 
    );
  };
  