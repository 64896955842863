import { useState, useEffect } from "react";
import LoadingIndicator from "./LoadingIndicator";

interface ModalLazyLoadedImage {
    src: string;
    alt: string;
  }
export  const ModalLazyLoadedImage: React.FC<ModalLazyLoadedImage> = ({ src, alt }) => {
    const [imageSrc, setImageSrc] = useState<string | null>(null);
  
    useEffect(() => {
      let img = new Image();
      img.src = src;
      img.onload = () => {
        setImageSrc(src);
      };
      return () => {
        img.onload = null;
      };
    }, [src]);
  
    return imageSrc ? (
      <img src={imageSrc} alt={alt} className="full-width" /> 
    ) : (
      <LoadingIndicator/>
    );
  };