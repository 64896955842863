import React, { createContext, useContext, useState , useRef , useEffect } from 'react';
import { useNavigate , useParams } from 'react-router-dom';
import { v4 as uuidv4 } from "uuid";
import { fetchUserInfo } from '../../pages/Chat/partials/chatapi';
import api from '../../utils/axiosInstance';
import ReactMarkdown from "react-markdown";

interface ConversationItem {
    id: string;
    type: string;
    content: string | JSX.Element;
    runId: string;
  }
  
  interface FileTypeData {
    preview: string;
    link: string;
    doc_id: string;
    gallery_type: string;
  }
  interface MarkdownEditContent {
    key: any;
    ref: any;
    props: {
      children: string;
    };
  }

  export const initialMarkdownEditContent: MarkdownEditContent = {
    key: null,
    ref: null,
    props: {
      children: "",
    },
  };
interface ChatContextType {
  userQuery: string;
  setUserQuery: React.Dispatch<React.SetStateAction<string>>;
  conversation: ConversationItem[];
  setConversation: React.Dispatch<React.SetStateAction<ConversationItem[]>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  conversationStarted: boolean;
  setConversationStarted: React.Dispatch<React.SetStateAction<boolean>>;
  filetypeData: FileTypeData[];
  setFileTypeData: React.Dispatch<React.SetStateAction<FileTypeData[]>>;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
  fileName: string;
  setFileName: React.Dispatch<React.SetStateAction<string>>;
  userParameters: string[];
  setUserParameters: React.Dispatch<React.SetStateAction<string[]>>;
  isuserparameters: boolean;
  setIsuerParameters: React.Dispatch<React.SetStateAction<boolean>>;
  generatedlink: string[];
  setGeneratedLink: React.Dispatch<React.SetStateAction<string[]>>;
  generatedhtmlLink: string[];
  setGeneratedHtmlLink: React.Dispatch<React.SetStateAction<string[]>>;
  userQueries: { [key: string]: string | undefined };
  setUserQueries: React.Dispatch<React.SetStateAction<{ [key: string]: string | undefined }>>;
  prevUserParameters: number | undefined;
  setprevUserParameters: React.Dispatch<React.SetStateAction<number | undefined>>;
  htmlApiCallCompleted: boolean;
  setHtmlApiCallCompleted: React.Dispatch<React.SetStateAction<boolean>>;
  finaluserParameters: string[];
  setFinaluserParameters: React.Dispatch<React.SetStateAction<string[]>>;
  chatContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  generatingloading: boolean;
  setGeneratingLoading: React.Dispatch<React.SetStateAction<boolean>>;
  finalgeneratingloading: boolean;
  setFinalGeneratingLoading: React.Dispatch<React.SetStateAction<boolean>>;
  navigate: any; // Replace 'any' with the correct type if possible
  initialLetter: string;
  setInitialLetter: React.Dispatch<React.SetStateAction<string>>;
  token: string | null;
  uniqueId: string;
  setUniqueId: React.Dispatch<React.SetStateAction<string>>;
  UserInitialPrompt: string;
  SetUserInitialPrompt: React.Dispatch<React.SetStateAction<string>>;
  nestedArray: string[];
  setNestedArray: React.Dispatch<React.SetStateAction<string[]>>;
  pdflink: string | string[];
  setPdfLink: React.Dispatch<React.SetStateAction<string | string[]>>;
  doclink: string | string[];
  setDocLink: React.Dispatch<React.SetStateAction<string | string[]>>;
  generateddocumentloading: boolean;
  setGeneratingdocumentLoading: React.Dispatch<React.SetStateAction<boolean>>;
  generatedRunId: string;
  setGeneratedRunId: React.Dispatch<React.SetStateAction<string>>;
  genereatedDocmenetType: string;
  setGeneratedDocumentType: React.Dispatch<React.SetStateAction<string>>;
  regenerateCount: number;
  setRegenerateCount: React.Dispatch<React.SetStateAction<number>>;
  content: string;
  setContent: React.Dispatch<React.SetStateAction<string>>;
  previewlink: string[];
  setPreviewLink: React.Dispatch<React.SetStateAction<string[]>>;
  iscontinuegenerating: boolean;
  setContinueGenerating: React.Dispatch<React.SetStateAction<boolean>>;
  isregenerating: boolean;
  setIsRegenerating: React.Dispatch<React.SetStateAction<boolean>>;
  updatedParameters: string[];
  setUpdatedParameter: React.Dispatch<React.SetStateAction<string[]>>;
  previewLinkIndices : number[]
  setPreviewLinkIndices : React.Dispatch<React.SetStateAction<number[]>>;
  chatId : any
  showdoc: boolean
  setShowDoc: React.Dispatch<React.SetStateAction<boolean>>
  showEditModal:boolean
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>
  markdowneditcontent: MarkdownEditContent
  setMarkdownEditContent: React.Dispatch<React.SetStateAction<MarkdownEditContent>>
  handlegeneratedocument: () => Promise<void>
  renderLink: (props: any) => JSX.Element

}

const ChatContext = createContext<ChatContextType | undefined>(undefined);

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChatContext must be used within a ChatProvider');
  }
  return context;
};

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userQuery, setUserQuery] = useState<string>("");
  const [conversation, setConversation] = useState<ConversationItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [conversationStarted, setConversationStarted] = useState<boolean>(false);
  const [filetypeData, setFileTypeData] = useState<FileTypeData[]>([]);
  const [error, setError] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [userParameters, setUserParameters] = useState<string[]>([]);
  const [isuserparameters, setIsuerParameters] = useState<boolean>(false);
  const [generatedlink, setGeneratedLink] = useState<string[]>([]);
  const [generatedhtmlLink, setGeneratedHtmlLink] = useState<string[]>([]);
  const [userQueries, setUserQueries] = useState<{ [key: string]: string | undefined; }>({});
  const [prevUserParameters, setprevUserParameters] = useState<number>();
  const [htmlApiCallCompleted, setHtmlApiCallCompleted] = useState<boolean>(false);
  const [finaluserParameters, setFinaluserParameters] = useState<string[]>([]);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const [generatingloading, setGeneratingLoading] = useState<boolean>(false);
  const [finalgeneratingloading, setFinalGeneratingLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const [initialLetter, setInitialLetter] = useState<string>("");
  const token = localStorage.getItem("token");
  const { chatId  } = useParams<{ chatId: string }>();
  const [uniqueId, setUniqueId] = useState<string>(chatId || uuidv4());
  const [UserInitialPrompt, SetUserInitialPrompt] = useState<string>("");
  const [nestedArray, setNestedArray] = useState<string[]>([]);
  const [pdflink, setPdfLink] = useState<string | string[]>([]);
  const [doclink, setDocLink] = useState<string | string[]>([]);
  const [generateddocumentloading, setGeneratingdocumentLoading] = useState(false);
  const [generatedRunId, setGeneratedRunId] = useState<string>("");
  const [genereatedDocmenetType, setGeneratedDocumentType] = useState<string>("");
  const initialCount = localStorage.getItem("regenerateCount");
  const [regenerateCount, setRegenerateCount] = useState<number>(
    initialCount !== null ? Number(initialCount) : 2
  );
  const [content, setContent] = useState<string>("");
  const [previewlink, setPreviewLink] = useState<string[]>([]);
  const [iscontinuegenerating, setContinueGenerating] = useState<boolean>(false);
  const [isregenerating, setIsRegenerating] = useState<boolean>(false);
  const [updatedParameters, setUpdatedParameter] = useState<string[]>([]);
  const [previewLinkIndices, setPreviewLinkIndices] = useState<number[]>([]);

  const [showdoc, setShowDoc] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [markdowneditcontent, setMarkdownEditContent] = useState(
    initialMarkdownEditContent
  );
  

  useEffect(() => {
    const savedConversation = localStorage.getItem("conversation");
    if (savedConversation) {
      setConversation(JSON.parse(savedConversation));
    }
  }, []);

  // Update this useEffect hook to save conversation history to localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem("conversation", JSON.stringify(conversation));
  }, [conversation]);

    // Update this useEffect hook to scroll to the bottom of the chat container when conversation changes
    useEffect(() => {
      if (chatContainerRef.current) {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }
    }, [conversation,finalgeneratingloading,generatingloading , loading]);
  
    useEffect(() => {
      const fetchUserData = async () => {
        if (!token) {
          console.error("Token is null");
          return;
        }
  
        try {
          const userData = await fetchUserInfo(token);
          setInitialLetter(userData.given_name[0]);
        } catch (error) {
          console.error("error");
          setError("Something went wrong please try again. Please try again.")
        }
      };
  
      fetchUserData();
    }, [token]);
  


    const ID_Token = localStorage.getItem("idtoken");
    const renderLink = (props: any) => {
      return <a {...props} target="_blank" rel="noopener noreferrer" />;
    };
  
    const handlegeneratedocument = async () => {
      console.log("userQueries",userQueries)
      const keys = Object.keys(userQueries).slice(1);
      console.log("keys",keys)
  
      const updatedUserQueries: { [key: string]: string } = {};
      keys.forEach((key, index) => {
        if (key !== undefined) {
          const newKey = finaluserParameters[index];
          updatedUserQueries[newKey] = userQueries[key] as string;
        }
      });
  
      const queryparams = {
        user_params: {
          params: updatedUserQueries,
        },
        link: {
          links: generatedlink,
        },
        user_prompt: {
          prompt: UserInitialPrompt,
        },
      };
  
      localStorage.setItem(
        "updatedUserQueries",
        JSON.stringify(updatedUserQueries)
      );
  
      const stringifiedGeneratedLink = generatedlink.map((innerArray) =>
        JSON.stringify(innerArray)
      );
  
      // Store the stringified array in localStorage
      localStorage.setItem(
        "generatedlink",
        JSON.stringify(stringifiedGeneratedLink)
      );
      localStorage.setItem("UserInitialPrompt", UserInitialPrompt);
  
      const messageId = chatId  || uuidv4();
  
      setGeneratingLoading(true);
      try {
        const response = await api.post(
          `/api/generation/generate_content/?identity_token=${ID_Token}`,
          queryparams
        );
        setRegenerateCount(2);
        localStorage.setItem("regenerateCount", "2");
  
        const type = "valmiki-bot";
        const ResponseData = response.data.data.content_run_id.md_content;
        const run_id = response.data.data.content_run_id.run_id
  
        if (response && response.data.data) {
          let markdownContent = response.data.data.content_run_id.md_content;
  
          if (Array.isArray(markdownContent)) {
            markdownContent = markdownContent.join("\n");
          }
  
          localStorage.setItem("markdowncontent", markdownContent);
          setContent(markdownContent);
  
          const GeneratedContent = (
            <ReactMarkdown children={markdownContent} components={{ a: renderLink }} />
          );
  
          const botMessageItem: ConversationItem = {
            id: messageId,
            type: type,
            content: GeneratedContent, // Pass the rendered Markdown content string
            runId : run_id
          };
  
          setConversation((prevConversation) => [
            ...prevConversation,
            botMessageItem,
          ]);
        }
  
        setGeneratingLoading(false);
        setGeneratedRunId(response.data.data.content_run_id.run_id);
        localStorage.setItem("runId", response.data.data.content_run_id.run_id);
        setGeneratedDocumentType(response.data.data.doc_type);
        localStorage.setItem(
          "genereatedDocmenetType",
          response.data.data.doc_type
        );
  
        setUpdatedParameter(response.data.data.updated_parameters);
        localStorage.setItem(
          "updatedparameters",
          JSON.stringify(response.data.data.updated_parameters)
        );
        // setUserQueries({});
      } catch (error) {
        console.error("Error:", error);
        setError("Something went wrong please try again. Please try again.")
    
        // setUserQueries({});
        setGeneratingLoading(false);
      } finally {
        setGeneratingLoading(false);
        setHtmlApiCallCompleted(false);
        // setUserQueries({});
      }
    };

    useEffect(() => {
      if (!isuserparameters && htmlApiCallCompleted) {
        handlegeneratedocument();
      }
    }, [isuserparameters, htmlApiCallCompleted]);


  const value: ChatContextType = {
    userQuery,
    setUserQuery,
    conversation,
    setConversation,
    loading,
    setLoading,
    conversationStarted,
    setConversationStarted,
    filetypeData,
    setFileTypeData,
    error,
    setError,
    fileName,
    setFileName,
    userParameters,
    setUserParameters,
    isuserparameters,
    setIsuerParameters,
    generatedlink,
    setGeneratedLink,
    generatedhtmlLink,
    setGeneratedHtmlLink,
    userQueries,
    setUserQueries,
    prevUserParameters,
    setprevUserParameters,
    htmlApiCallCompleted,
    setHtmlApiCallCompleted,
    finaluserParameters,
    setFinaluserParameters,
    chatContainerRef,
    generatingloading,
    setGeneratingLoading,
    finalgeneratingloading,
    setFinalGeneratingLoading,
    navigate,
    initialLetter,
    setInitialLetter,
    token,
    uniqueId,
    setUniqueId,
    UserInitialPrompt,
    SetUserInitialPrompt,
    nestedArray,
    setNestedArray,
    pdflink,
    setPdfLink,
    doclink,
    setDocLink,
    generateddocumentloading,
    setGeneratingdocumentLoading,
    generatedRunId,
    setGeneratedRunId,
    genereatedDocmenetType,
    setGeneratedDocumentType,
    regenerateCount,
    setRegenerateCount,
    content,
    setContent,
    previewlink,
    setPreviewLink,
    iscontinuegenerating,
    setContinueGenerating,
    isregenerating,
    setIsRegenerating,
    updatedParameters,
    setUpdatedParameter,
    previewLinkIndices,
    setPreviewLinkIndices,
    chatId,
    showdoc,
  setShowDoc,
  showEditModal,
  setShowEditModal,
  markdowneditcontent,
  setMarkdownEditContent,
  handlegeneratedocument,
  renderLink
  };



  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
