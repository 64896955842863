import Sidebar from "../../components/Sidebar";
import PromtBox from "./partials/PromptInput";
import Template from "./partials/Template";

function Templates() {
  return (
    <div className="container-fluid mt-3">
      <div className="main-container d-flex gx-5">
        {/* <Sidebar></Sidebar> */}
        <div className="content-2 round ms-4 align-item-buttom">
          {/* <PromtBox/>
           */}
          <Template />
        </div>
      </div>
    </div>
  );
}

export default Templates;
