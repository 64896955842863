import { Modal } from "react-bootstrap";

interface ConfirmationModalProps {
    onConfirm: () => void;
    onCancel: () => void;
    showDoc:  boolean
    setDocShow: React.Dispatch<React.SetStateAction<boolean>>
}


export const ConfirmationModal = ({ onConfirm, onCancel ,setDocShow , showDoc} : ConfirmationModalProps) => {
    const handleclose = () => {
        setDocShow(false); }
    return (
        
        <Modal show={showDoc} onHide={() => handleclose()} centered >
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body>

      
            
        <div className="docUpload">
          
            <h2>Are you sure you want to switch the template?</h2>
            <div className="text-center mt-4">
                <button className='univ-btn mx-1' onClick={onConfirm}>Yes</button>
                <button className = "btn btn-danger mx-1"onClick={onCancel}> <span className='my-2'>No</span></button>
                </div>
            </div>
       
        </Modal.Body>
        </Modal>
        
    );
};