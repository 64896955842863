import React, {useEffect } from "react";
import Sidebar from "../../components/Sidebar";
import ChatTitle from "./partials/ChatTitle";
import api from "../../utils/axiosInstance";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { BsZoomIn } from "react-icons/bs";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

import { getFileListWithPaginationContent } from "./partials/FileList";
import Loading from "../../components/Loading";
import { SystemChatIcon, UserChatIcon } from "./partials/ChatIcon";
import PromptInputBox from "./partials/PromptInput";
import { fetchUserInfo } from "./partials/chatapi";
import ErrorAlert from "../../components/ErrorAlert";
import { FileType } from "./partials/FileType";

import PreviewGeneratedModal from "./partials/PreviewGeneratedModal";
import { FaRegEdit } from "react-icons/fa";

import {
  GeneratedDocumentProps,
  GenerateDocumentResponse,
} from "./partials/interface";

import PreviewImage from "../../assets/images/preview.jpg";
import ReactMarkdown from "react-markdown";
import MarkdownToQuill from "../Markdown/markdown";

import { useChatContext } from "../../context/ChatContext/chatcontext";
// import useLoadingStore from "../../Store/useLoadingStore";


interface ConversationItem {
  id: string;
  type: string;
  content: string | JSX.Element;
  runId : string
}

interface FileTypeData {
  preview: string;
  link: string;
  doc_id: string;
  gallery_type: string;
}





const Chat: React.FC = () => {

  // const {loading, generatingloading, finalgeneratingloading, generateddocumentloading,
  //   setLoading, setGeneratingLoading, setFinalGeneratingLoading, setGeneratingdocumentLoading} = useLoadingStore()
   

  const {loading, generatingloading, finalgeneratingloading, generateddocumentloading,
    setLoading, setGeneratingLoading, setFinalGeneratingLoading, setGeneratingdocumentLoading,userQuery, setUserQuery ,conversationStarted, setConversationStarted ,error, setError  ,fileName, setFileName
    ,userParameters, setUserParameters ,generatedlink, setGeneratedLink  ,
     generatedhtmlLink, setGeneratedHtmlLink ,isuserparameters, setIsuerParameters, prevUserParameters, 
     setprevUserParameters, htmlApiCallCompleted, setHtmlApiCallCompleted, finaluserParameters, setFinaluserParameters,
     initialLetter, setInitialLetter, UserInitialPrompt, SetUserInitialPrompt, nestedArray, setNestedArray , pdflink,
      setPdfLink,doclink, setDocLink  , generatedRunId, setGeneratedRunId , genereatedDocmenetType, setGeneratedDocumentType,
      regenerateCount, setRegenerateCount ,updatedParameters, setUpdatedParameter ,isregenerating, setIsRegenerating,content, setContent ,previewlink, setPreviewLink ,
       iscontinuegenerating, setContinueGenerating,conversation, setConversation,filetypeData, setFileTypeData,userQueries,
       chatContainerRef , setUserQueries ,navigate,uniqueId, setUniqueId,token,   previewLinkIndices,   showdoc,
       setShowDoc,
       showEditModal,
       setShowEditModal,
       setPreviewLinkIndices, chatId ,  handlegeneratedocument,
       renderLink,
       markdowneditcontent,
  setMarkdownEditContent
  } = useChatContext()


  const ID_Token = localStorage.getItem("idtoken");

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [conversation,finalgeneratingloading,generatingloading , loading]);

  const handleSendQuery = async () => {
    setLoading(true);
    setError("");
    if (userQuery.trim() === "") {
      setLoading(false);
      return;
    }

    const requestBody = {
      user_query: userQuery,
    };

    // Set the id of the message to chatId if available, or generate a unique id
    const messageId = chatId || uuidv4();

    // Add the submitted query to the conversation
    setConversation((prevConversation) => [
      ...prevConversation,
      { id: messageId, type: "user-command", content: userQuery , runId:""},
    ]);
    navigate(`/chat/${messageId}`);

    // Set conversationStarted to true after the first query is sent
    setConversationStarted(true);

    try {
      const response = await api.post("/api/chat/", requestBody);
      setLoading(false);

      // Add the query response to the conversation
      setConversation((prevConversation) => [
        ...prevConversation,
        {
          id: messageId,
          type: "valmiki-bot",
          content: response.data.query_response,
          runId:""
        },
      ]);

      // Check if the response contains a doc_type value
      if (response.data.doc_type) {
        SetUserInitialPrompt(userQuery);

        const params: { doc_type: any } = {
          doc_type: response.data.doc_type,
        };
        // Fetch the file type information
        const fileTypeResponse = await api.post(
          `/api/chat/file-type/?identity_token=${ID_Token}`,
          params
        );
        setFileTypeData(fileTypeResponse.data);

        const fileContent = getFileListWithPaginationContent({
          fileTypeResponse,
        });

        setConversation((prevConversation) => [
          ...prevConversation,
          { id: messageId, type: "", content: fileContent , runId:""},
        ]);
      }

      setUserQuery("");
    } catch (error: any) {
      if (error.response && error.response.data.detail) {
        console.error("Error:", error.response.data.detail);
        const errorcontent = (
          <div className={`message valmiki-bot`}>
            {error.response.data.detail}
          </div>
        );
        setConversation((prevConversation) => [
          ...prevConversation,
          { id: messageId, type: "", content: errorcontent , runId:"" },
        ]);
        setUserQuery("");
        setError("Something went wrong please try again. Please try again.");
      } else {
        console.error("Unknown error:", error);
        setError("Something went wrong please try again. Please try again.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (userQuery.trim() === "") {
      return;
    }

    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      setUserQuery("");
      if (isuserparameters) {
        handleUserParameter(userParameters);
      } else {
        handleSendQuery();
      }
    }
  };



  const handlegeneratedtemplatepreview = async (
    contentdoclink: any,
    contentpdflink: any,
    contentpreview_link: any,
    contentfile_name: any
  ) => {
    setGeneratingdocumentLoading(true);
    setError("")
    setShowDoc(true);
    const queryParams = {
      doc_presigned_link: contentdoclink,
      pdf_presigned_link: contentpdflink,
      preview_presigned_link: contentpreview_link,
      filename: contentfile_name,
    };

    try {
      const { data } = await api.post(
        "/api/generate-s3-presigned-url/",
        queryParams
      );

      setNestedArray(data.data.preview_link);
      setFileName(data.data.file_name);
      setDocLink(data.data.doc)
      setPdfLink(data.data.pdf)
    } catch (error) {
      console.error("Error fetching categories:", error);
      setError("Something went wrong please try again. Please try again.");
    } finally {
      // setGeneratingdocumentLoading(false);
    }
  };

  const handleUserParameter = (userParameters: string[]) => {
    // if (userQuery.trim() === "") {
    //   return;
    // }
    const messageId = chatId  || uuidv4();
    if (prevUserParameters && userParameters.length < prevUserParameters) {
      const userQueryItem: ConversationItem = {
        id: messageId,
        type: "user-command",
        content: userQuery,
        runId : ""
      };

      setConversation((prevConversation) => [
        ...prevConversation,
        userQueryItem,
      ]);
    }
    setIsuerParameters((prevIsUserParameters) => {
      if (prevIsUserParameters) {
        if (userParameters.length > 0) {
          const nextUserParameter = userParameters[0];

          const type = "valmiki-bot";

          const botMessageItem: ConversationItem = {
            id: messageId,
            type: type,
            content: `Please enter ${nextUserParameter} or N/A`,
            runId:""
          };

          setConversation((prevConversation) => [
            ...prevConversation,
            botMessageItem,
          ]);

          setUserParameters((prevUserParameters) =>
            prevUserParameters.slice(1)
          );
        } else {
          setIsuerParameters(false);
          // handlegeneratedocument();
        }
      } else {
        setIsuerParameters(false);
        // handlegeneratedocument();
      }
      return prevIsUserParameters;
    });
    setUserQueries((prevUserQueries) => {
      const newKey = `additionalProp${Object.keys(prevUserQueries).length + 1}`;
      console.log("newKey",newKey)
      return {
        ...prevUserQueries,
        [newKey]: userQuery,
      };
    });
  };



  const handleNewChat = () => {
    const newUniqueId = uuidv4();
    setUniqueId(newUniqueId);
    navigate(`/chat/${newUniqueId}`);
    setLoading(false);
    setGeneratingLoading(false);
    setFinalGeneratingLoading(false);
    setConversation([]);
    setUserParameters([]);
    setIsuerParameters(false);

    setHtmlApiCallCompleted(false);
    setError("");
  };

  const filteredChatHistory = conversation.filter(
    (message) => message.id === chatId 
  );

  const handleregenerateContent = async () => {
    setIsRegenerating(true);
    const storedGeneratedLink = localStorage.getItem("generatedlink");

    // Parse the string back into an array of stringified arrays
    const parsedGeneratedLink = storedGeneratedLink
      ? JSON.parse(storedGeneratedLink)
      : [];

    // Parse each stringified inner array back into its original array form
    const restoredGeneratedLink = parsedGeneratedLink.map(
      (innerArrayString: string) => JSON.parse(innerArrayString)
    );
    const DocType = localStorage.getItem("genereatedDocmenetType");

    const queryparams = {
      doc_data: {
        doc_type: DocType,
        updated_parameters: JSON.parse(
          localStorage.getItem("updatedUserQueries") || "{}"
        ),
      },
      link: {
        links: restoredGeneratedLink,
      },
      user_prompt: {
        prompt: localStorage.getItem("UserInitialPrompt"),
      },
      feedbacks: {
        run_id: localStorage.getItem("runId"),
        feedback: 0,
        comment: "string",
      },
    };

    if (regenerateCount <= 2) {
      const messageId = chatId  || uuidv4();

      setGeneratingLoading(true);
      setError("")
      try {
        const response = await api.post(
          `/api/generation/regenerate_content/?identity_token=${ID_Token}`,
          queryparams
        );
        const type = "valmiki-bot";
        const ResponseData = response.data.data.md_content;
        const run_id = response.data.data.run_id

        if (response && response.data.data) {
          let markdownContent = response.data.data.md_content;
          
        if (Array.isArray(markdownContent)) {
          markdownContent = markdownContent.join("\n");
        }


          localStorage.setItem("markdowncontent", markdownContent);
          const GeneratedContent = (
            <ReactMarkdown children={markdownContent} components={{ a: renderLink }} />
          );

          const botMessageItem: ConversationItem = {
            id: messageId,
            type: type,
            content: GeneratedContent,
            runId : run_id
          };

          setConversation((prevConversation) => [
            ...prevConversation,
            botMessageItem,
          ]);
        }

        setGeneratingLoading(false);
        setGeneratedRunId(response.data.data.run_id);
        localStorage.setItem("runId", response.data.data.run_id);
        setIsRegenerating(false);
        // setUserQueries({});
      } catch (error) {
        console.error("Error:", error);
        // setUserQueries({});
        setError("Something went wrong please try again. Please try again.");
        setGeneratingLoading(false);
        setIsRegenerating(false);
      } finally {
        setGeneratingLoading(false);
        setHtmlApiCallCompleted(false);
        setIsRegenerating(false);
        // setUserQueries({});
      }

      setRegenerateCount((prevCount) => prevCount - 1);
      const updatedCount = regenerateCount - 1;

      localStorage.setItem("regenerateCount", updatedCount.toString());
    }
  };

  const handlecontinuegenerateContent = async (content:any , runId:string) => {
    console.log("content", content)
    console.log("runId " , runId)
    setContinueGenerating(true);
    const storedGeneratedLink = localStorage.getItem("generatedlink");

    // Parse the string back into an array of stringified arrays
    const parsedGeneratedLink = storedGeneratedLink
      ? JSON.parse(storedGeneratedLink)
      : [];
    // Parse each stringified inner array back into its original array form
    const restoredGeneratedLink = parsedGeneratedLink.map(
      (innerArrayString: string) => JSON.parse(innerArrayString)
    );
    const DocType = localStorage.getItem("genereatedDocmenetType");
    const markdownContent = content.props.children;
    console.log("localstoragecontent" , localStorage.getItem("markdowncontent"))
    console.log("getanothercontent", markdownContent)

    // Split the Markdown content into sections based on the Markdown headings
    let sections = markdownContent?.split(/\n(?=\#\#)/) || [];

    // Remove empty strings from the sections array
    sections = sections.filter((section: string) => section.trim() !== '');
    let formattedContent;

    if (DocType && (DocType.toLowerCase() === 'ppt' || DocType.toLowerCase() === 'pptx')) {
      formattedContent = { content: sections };
     
  } else {
      formattedContent = { content: markdownContent };
  }

    console.log("formattedContent",formattedContent)


     const queryparams = {
       markdown_content: formattedContent, 
             link: {
        links: restoredGeneratedLink,
      },
      doc_data: {
        doc_type: DocType,
        updated_parameters: JSON.parse(
          localStorage.getItem("updatedUserQueries") || "{}"
        ),
      },
      user_prompt: {
        prompt: localStorage.getItem("UserInitialPrompt"),
      },
      feedbacks: {
        run_id: localStorage.getItem("runId"),
        feedback: 0,
        comment: "string",
      },
    };

    const messageId = chatId  || uuidv4();

    setFinalGeneratingLoading(true);
    setError("")
    try {
      const response = await api.post(
        `/api/generation/generate_docs/?identity_token=${ID_Token}`,
        queryparams
      );
      const type = "valmiki-bot";
      const ResponseData = response.data.data;
      const PreviewLink = response.data.data.preview_link;

      setPreviewLink(PreviewLink);
      setDocLink(response.data.data.doc);
      setPdfLink(response.data.data.pdf);
      setFileName(response.data.data.file_name);

      if (response && response.data.data) {
        const botMessageItem: ConversationItem = {
          id: messageId,
          type: type,
          content: ResponseData,
          runId:""
        };
        setConversation((prevConversation) => [
          ...prevConversation,
          botMessageItem,
        ]);
      }

      setFinalGeneratingLoading(false);
      setUserQueries({});
      setContinueGenerating(false);
    } catch (error) {
      console.error("Error:", error);
      // setUserQueries({});
      setFinalGeneratingLoading(false);
      setContinueGenerating(false);
      setError("Something went wrong please try again. Please try again.");
    } finally {
      setFinalGeneratingLoading(false);
      setHtmlApiCallCompleted(false);
      setUserQueries({});
      setContinueGenerating(false);
    }
  };


  const handleEdit = (markdowncontent: any) => {
    setMarkdownEditContent(markdowncontent);
    setShowEditModal(true);
  };
  
    // Iterate through the conversation items to find preview links and update state accordingly
    useEffect(() => {
        const indices: number[] = [];
        conversation.forEach((item, index) => {
            if (item.content && item.content.hasOwnProperty("preview_link")) {
                indices.push(index);
            }
        });
        setPreviewLinkIndices(indices);
    }, [conversation]);
  return (
    <div className="chatWrapper">
      <div className="container-fluid">
        <div className="main-container d-flex gx-5">
          <Sidebar
            conversation={conversation}
            handleNewChat={handleNewChat}
            generatingloading={generatingloading}
            finalgeneratingloading={finalgeneratingloading}
            loading={loading}
          />
          {/* {showDoc && <div>test</div>} */}

          <div className="content-1 ms-4 d-flex flex-column">
            <div className="top-content" ref={chatContainerRef}>
              <ChatTitle
                conversationStarted={conversationStarted}
                conversationlength={conversation.length}
                filteredChatHistory={filteredChatHistory}
              />
              {showdoc && (
                <PreviewGeneratedModal
                  show={showdoc}
                  setShowDoc={setShowDoc}
                  pdflink={pdflink}
                  doclink={doclink}
                  nestedArray={nestedArray}
                  generateddocumentloading={generateddocumentloading}
                  setGeneratingdocumentLoading={setGeneratingdocumentLoading}
                  fileName={fileName}
                  setDocLink={setDocLink}
                  setPdfLink={setPdfLink}
                  setNestedArray={setNestedArray}
                />
              )}

              <>
                {conversation.map((item, index) => {
                  const isLastItem = index === conversation.length - 1;
                  const hasPreviewLink = item.content && item.content.hasOwnProperty("preview_link");
                
                  // Check if the "Continue" button should be disabled
                  const disableContinueButton = previewLinkIndices.some(idx => index < idx);
  
                  return (
                    <div key={index}>
                      {item.content !== null && item.content !== "" && (
                        <>
                          {item.type === "user-command" ? (
                            <UserChatIcon initialLetter={initialLetter} />
                          ) : (
                            <SystemChatIcon />
                          )}

                          {item.content &&
                          typeof item.content === "object" &&
                          item.content.props &&
                          item.content.props.fileTypeResponse ? (
                            <>
                              <div className={`message ${item.type} mx-5`}>
                                <FileType
                                  fileTypeResponse={
                                    item.content.props.fileTypeResponse
                                  }
                                  setLoading={setLoading}
                                  setUserParameters={setUserParameters}
                                  setIsuerParameters={setIsuerParameters}
                                  userParameters={userParameters}
                                  setFinaluserParameters={
                                    setFinaluserParameters
                                  }
                                  setprevUserParameters={setprevUserParameters}
                                  handleUserParameter={handleUserParameter}
                                  setGeneratedHtmlLink={setGeneratedHtmlLink}
                                  setHtmlApiCallCompleted={
                                    setHtmlApiCallCompleted
                                  }
                                  setGeneratedLink={setGeneratedLink}
                                  loading={loading}
                                  setUserQueries = {setUserQueries}
                                />
                              </div>
                            </>
                          ) : item.content &&
                            item.content.hasOwnProperty("preview_link") ? (
                            <div className={`message mx-5`}>
                              <GenerateDocument
                                content={item.content}
                                handlegeneratedtemplatepreview={
                                  handlegeneratedtemplatepreview
                                }
                                setDocLink={setDocLink}
                                setPdfLink={setPdfLink}
                                setNestedArray={setNestedArray}
                              />
                            </div>
                          ) : item.content &&
                            typeof item.content === "object" &&
                            item.content.props &&
                            item.content.props.children ? (
                            <>
                              <div className={`message ${item.type} mx-5`}>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <div>
                                    <h4>Generated Content</h4>
                                  </div>
                                  {isLastItem &&
                                    !generatingloading &&
                                    (
                                      <span
                                        className="blue-edit"
                                        data-tip="Edit"
                                        data-for="edit-tooltip"
                                      >
                                        <FaRegEdit
                                          size={24}
                                          title="Edit"
                                          onClick={() =>
                                            handleEdit(item.content)
                                          }
                                          style={
                                            iscontinuegenerating ||
                                            isregenerating
                                              ? {
                                                  cursor: "not-allowed",
                                                  opacity: 0.5,
                                                } // Add styles for disabled state
                                              : { cursor: "pointer" } // Change cursor to pointer when not in disabled state
                                          }
                                        />
                                        <Tooltip id="edit-tooltip" />
                                      </span>
                                    )}
                                </div>

                                {/* <ReactMarkdown className="markdownclass">
                                  {item.content.props.children}
                                </ReactMarkdown> */}
                                <ReactMarkdown className="markdownclass" children={item.content.props.children} components={{ a: renderLink }} />

                                {isLastItem &&
                                  !generatingloading &&
                                  regenerateCount > 0 && (
                                    <h4 className="text-danger">
                                      Would you like to use the current content
                                      for document generation or would you
                                      prefer to generate new content?
                                      <i>
                                        (Note: You can only regenerate content two times, and the system will use the latest generated content for document 
                                        creation. Click "Continue" to proceed or "Regenerate" to generate new content.
                                        )
                                      </i>
                                      <div>
                                        You have {(regenerateCount+ 1) - 1} attempts
                                        left.
                                      </div>
                                    </h4>
                                  )}
                                { (
                                  <div className="align-item-end d-flex justify-content-end">
                                    <div className="mx-3">
                                      {" "}{!disableContinueButton  
                                      && ( <button
                                        className="univ-btn p-3 py-2 mr-2"
                                        // onClick={() => {
                                        //   if (React.isValidElement(item.content)) {
                                        //     const element = item.content as React.ReactElement<any, string | React.JSXElementConstructor<any>>;
                                        //     handlecontinuegenerateContent(element.props.children , item.runId);
                                        //   }
                                        // }}

                                        onClick={() => { handlecontinuegenerateContent
                                          (item.content , item.runId)
                                        }}
                                        
                                        
                                        disabled={
                                          iscontinuegenerating || isregenerating
                                        }
                                        style={
                                          iscontinuegenerating || isregenerating
                                            ? { cursor: "not-allowed" }
                                            : {}
                                        }
                                      >
                                        Continue
                                      </button>)}
                                     
                                    </div>
                                    {isLastItem && !generatingloading && regenerateCount > 0 && (
                                      <button
                                        className="univ-btn p-3 py-2"
                                        onClick={handleregenerateContent}
                                        disabled={
                                          isregenerating || iscontinuegenerating
                                        }
                                        style={
                                          iscontinuegenerating || isregenerating
                                            ? { cursor: "not-allowed" }
                                            : {}
                                        }
                                      >
                                        Regenerate
                                      </button>
                                    )}
                                  </div>
                                )}
                              </div>
                            </>
                          ) : (
                            <div className={`message ${item.type} mx-5`}>
                              {item.content}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  );
                })}

                {loading && <Loading loading={loading} />}
              </>

              {error && (
                <div className="mx-5">
                  <ErrorAlert error={error} />{" "}
                </div>
              )}

              {showEditModal && (
                <MarkdownToQuill
                  setShowEditModal={setShowEditModal}
                  showEditModal={showEditModal}
                  markdowneditcontent={markdowneditcontent}
                  conversation={conversation}
                  setConversation={setConversation}
                  setMarkdownEditContent={setMarkdownEditContent}
                />
              )}
              {generatingloading && (
                <div className="loading">
                  <div className="spinner-box mb-3">
                    Hang tight, your content is currently being generated.
                    <div className="pulse-container ms-3">
                      <div className="pulse-bubble pulse-bubble-1"></div>
                      <div className="pulse-bubble pulse-bubble-2"></div>
                      <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                  </div>
                </div>
              )}
              {finalgeneratingloading && (
                <div className="loading">
                  <div className="spinner-box mb-3">
                    Hold tight your document is being generated
                    <div className="pulse-container ms-3">
                      <div className="pulse-bubble pulse-bubble-1"></div>
                      <div className="pulse-bubble pulse-bubble-2"></div>
                      <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="bottom-content flex-grow-1 d-flex flex-column justify-content-end">
              <div className="mt-auto">
                <PromptInputBox
                  userQuery={userQuery}
                  setUserQuery={setUserQuery}
                  handleKeyDown={handleKeyDown}
                  isuserparameters={isuserparameters}
                  generatingloading={generatingloading}
                  finalgeneratingloading={finalgeneratingloading}
                  isregenerating = {isregenerating}
                  iscontinuegenerating = {iscontinuegenerating}
                  loading={loading}
                  userParameters={userParameters}
                  handleUserParameter={handleUserParameter}
                  handleSendQuery={handleSendQuery}
            
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const GenerateDocument = ({
  content,
  handlegeneratedtemplatepreview,

  setDocLink,
  setNestedArray,
  setPdfLink,
}: GeneratedDocumentProps) => {
 
  let contentdoclink: string | undefined;
  let contentpdflink: string | undefined;
  let contentpreview_link: [] | undefined;
  let contentfile_name: string | undefined;

  function isGenerateDocumentResponse(
    content: any
  ): content is GenerateDocumentResponse {
    return typeof content === "object" && "file" in content && "pdf" in content;
  }

  if (content) {
    if (
      typeof content !== "string" &&
      !React.isValidElement(content) &&
      isGenerateDocumentResponse(content)
    ) {
      contentdoclink = content.file;
      // setContentDocLink(contentdoclink);
      contentpdflink = content.pdf;
      contentpreview_link = content.preview_link;
      contentfile_name = content.file_name;
    }
  }

  return (
    <div className="message file-type mb-3">
      <ul className="file-list">
        <li className="file-item">
          <div
            className="fileDetail"
            onClick={() =>
              handlegeneratedtemplatepreview(
                contentdoclink,
                contentpdflink,
                contentpreview_link,
                contentfile_name
              )
            }
          >
            <BsZoomIn />
            <div style={{ textDecoration: "none" }}>Preview Template</div>
          </div>

          <a>
            <img src={PreviewImage} alt="generate document" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Chat;
