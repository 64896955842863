import { useState } from "react";
import Logo from "../assets/images/valmiki-logo.svg";
import LogInModal from "./LogInModal";
import UploadModal from "./UploadModal";
import { getAuthToken } from "../utils/token.utils";
import { useNavigate } from "react-router-dom";
import ConsentForm from "../pages/LandingPage/consentForm/consentform";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [show, setShow] = useState(false);
  const [showConsent, setShowConsent] = useState(false);
  const [showConsentForm, setShowConsentForm] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleCloseConsent = () => setShowConsent(false);
  const handleShow = () => setShow(true);

  const token = getAuthToken();
  const handlemydocumentclick = (event: any) => {
    event.preventDefault();
    navigate("/documents");
  };

  const handlelogoclick = (event: any) => {
    event.preventDefault();
    navigate("/");
  };

  const handleShowConsentForm = () => {
    setShowConsentForm(true);
    setShowConsent(true);
  };
  const handleConsentSubmit = () => {
    setShowConsentForm(false);
    setShow(true);
  };
  return (
    <>
      <header>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-2">
              <div className="siteLogo">
                <a href="/" onClick={handlelogoclick}>
                  <img src={Logo} alt="" />
                </a>
              </div>
            </div>
            <div className="col-xl-10">
              <div className="siteMenu">
                <ul>
                  {token && (
                    <>
                      <li>
                        <UploadModal />
                      </li>
                      <li>
                        <button
                          className="univ-btn-outline"
                          onClick={handlemydocumentclick}
                        >
                          My Documents
                        </button>
                      </li>
                    </>
                  )}
                  {!token && (
                    <li>
                      <button
                        className="univ-btn"
                        onClick={handleShowConsentForm}
                      >
                        Get Started
                      </button>
                      {showConsentForm && (
                        <ConsentForm
                          show={showConsent}
                          handleCloseConsent={handleCloseConsent}
                          onSubmit={handleConsentSubmit}
                        />
                      )}
                      <LogInModal
                        show={show}
                        handleClose={handleClose}
                        handleShow={handleShow}
                      />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default App;
