/**
 * Configuration options for AWS Amplify authentication and OAuth settings.
 */

const awsExports = {
  Auth: {
    userPoolId: process.env.REACT_APP_AUTH_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID,
  },
  oauth: {
    domain: process.env.REACT_APP_OAUTH_DOMAIN,
    scope: JSON.parse(process.env.REACT_APP_OAUTH_SCOPE || "[]"),
    redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGNIN,
    redirectSignUp: process.env.REACT_APP_OAUTH_REDIRECT_SIGNUP,
    redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGNOUT,
    responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE,
  },
};

export default awsExports;
