// ModalComponent.tsx
import React from 'react';
import { Modal, DropdownButton, Dropdown } from "react-bootstrap";
import { RiDeleteBin5Line } from "react-icons/ri";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import ModalImageLoader from './GeneratedModalImageLoader';
import LoadingIndicator from './LoadingIndicator';

interface ModalProps {
  showModal: boolean;
  handleClose: () => void;
  handleDownload: (format: string) => void;
  handlePrev: () => void;
  handleNext: () => void;
  currentIndex: number;
  nestedArray: string[];
  modalLoading: boolean;
  deleteDocument: () => void;
  pdflink: string | string[];
  doclink: string | string[]
}

const ModalComponent: React.FC<ModalProps> = ({
  showModal,
  handleClose,
  handleDownload,
  pdflink,
  doclink,
  handlePrev,
  handleNext,
  currentIndex,
  nestedArray,
  modalLoading,
  deleteDocument,
}) => (
  <Modal
    show={showModal}
    onHide={handleClose}
    centered
    className="PreviewModal"
    dialogClassName="custom-modal"
  >
        <Modal.Header closeButton>
  <div className="button-container previewmodal-button d-flex align-items-center justify-content-between">
    <div className="form-group text-center me-2 d-flex align-items-center">
     <span className='mx-2'> <button
        className="btn-danger "
        onClick={() => deleteDocument()}
      >
        <RiDeleteBin5Line />
      </button></span>
     
      <DropdownButton id="dropdown-basic-button" title="Download File">
       
                {doclink? (
                  <>
                    <Dropdown.Item onClick={() => handleDownload("pdf")}>
                      Download as Doc
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload("doc")}>
                      Download as PDF
                    </Dropdown.Item>
                  </>
                ) : (
                  <Dropdown.Item onClick={() => handleDownload("pdf")}>
                    Download as PPT
                  </Dropdown.Item>
                )}
              </DropdownButton>
    </div>
    <div>
      <button onClick={handlePrev} disabled={currentIndex === 0}>
        <MdKeyboardArrowLeft />
      </button>
      <button
        onClick={handleNext}
        disabled={currentIndex === nestedArray.length - 1}
      >
        <MdKeyboardArrowRight />
      </button>
    </div>
  </div>
</Modal.Header>
    <Modal.Body className="previewmodal p-0">
     
      {modalLoading ? (
        <LoadingIndicator/>
      ) : (
        // Image Display
        <ModalImageLoader currentIndex={currentIndex} nestedArray={nestedArray} />
      )}
    </Modal.Body>
  </Modal>
);

export default ModalComponent;
