import React from 'react'
import { FaPlus } from "react-icons/fa";
import Template1 from '../../../assets/images/template1.png'
import Template2 from '../../../assets/images/template2.png'
import Template3 from '../../../assets/images/template3.png'
import Template4 from '../../../assets/images/template4.png'

const Template= () => {
  return (
    <div className="container">
    <div className="mt-5">
      <div className="image-card-title mb-2">
        <h6>Select Templates</h6>
      </div>
      <div className="row">
      <div className="col-2">
          <div className="card-image ">
           
          </div>
        </div>
        <div className="col-2">
          <div className="card-image ">
           <img src={Template1} width={130} height={120} />
          </div>
        </div>
        <div className="col-2">
          <div className="card-image ">
           <img src={Template2}  width={130} height={120}/>
          </div>
        </div>
        <div className="col-2">
          <div className="card-image ">
           <img src={Template3} width={130} height={120}/>
          </div>
        </div>
        <div className="col-2">
          <div className="card-image ">
           <img src={Template4} width={130} height={120}/>
          </div>
        </div>
        <div className="col-2">
          <div className="card-image mt-2">
          <button className='univ-btn'><div className='mt-2'>
            <span className='mx-4  mt-4'><FaPlus/></span> Add Template</div></button>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  )
}

export default Template