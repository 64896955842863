import React, { SetStateAction, Dispatch } from "react";
import { ProgressBar } from "react-bootstrap";
import { CiFileOn } from "react-icons/ci";
import { IoCloseOutline } from "react-icons/io5";

/**
 * UploadedFileProgressbar component displays progress bars for uploaded files.
 * It allows users to remove uploaded files.
 *
 * Props:
 * - selectedFiles: Array of selected files
 * - uploadProgress: Array containing upload progress for each file
 * - setUploadProgress: Function to update the upload progress array
 * - setError: Function to set an error message
 * - setSelectedFiles: Function to update the selected files array
 */

interface UploadedFileProgressbarProps {
  selectedFiles: File[];
  uploadProgress: number[];
  setUploadProgress: Dispatch<SetStateAction<number[]>>;
  setError: Dispatch<SetStateAction<string>>;
  setSelectedFiles: Dispatch<SetStateAction<File[]>>;
  setTotalFileCount: Dispatch<SetStateAction<number>>;
}

const UploadedFileProgressbar: React.FC<UploadedFileProgressbarProps> = ({
  selectedFiles,
  uploadProgress,
  setError,
  setSelectedFiles,
  setUploadProgress,
  setTotalFileCount,
}) => {
  /**
   * Handles the removal of a file from the selected files array and its corresponding progress.
   * @param index - Index of the file to be removed
   */

  const handleFileRemove = (index: number) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);
    setTotalFileCount((prevCount) => prevCount - 1);
    const updatedProgress = [...uploadProgress];
    updatedProgress.splice(index, 1);
    setUploadProgress(updatedProgress);
    setError("");
  };

  return (
    <>
      {selectedFiles.length > 0 && (
        <div className="templateProgress">
          <h2 className="mt-3">Selected Files</h2>
          {selectedFiles.map((file, index) => (
            <ul key={index}>
              <li>
                <div className="d-flex align-items-center">
                  <CiFileOn className="fileIcon" />
                  <div className="templateDetail">
                    <span className="templateName">{file.name}</span>
                    <ProgressBar
                      variant="success"
                      now={uploadProgress[index]}
                    />
                  </div>
                  <div
                    className="deleteIcon ms-3"
                    onClick={() => handleFileRemove(index)}
                  >
                    <IoCloseOutline />
                  </div>
                </div>
              </li>
            </ul>
          ))}
        </div>
      )}
    </>
  );
};

export default UploadedFileProgressbar;
