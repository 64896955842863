import api from "../../../utils/axiosInstance";

export const getUploadedTemplates = async (ID_Token: string) => {
  try {
    const response = await api.get(
      `/api/gallery/uploaded-template/?identity_token=${ID_Token}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching uploaded templates:", error);
    throw error;
  }
};

// to delete uploaded templates
export const deleteDocument = async (docId: string) => {
  try {
    const response = await api.post(
      `api/gallery/delete-uploaded-template/?doc_id=${docId}`,
      null,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (response) {
      return true;
    } else {
      throw new Error("Failed to delete document");
    }
  } catch (error) {
    console.error("Error deleting document:", error);
    throw error;
  }
};

// to fetch generated templated of particular uploaded template

export const fetchGeneratedTemplate = async (
  docId: string,
  ID_Token: string
) => {
  try {
    const response = await api.get(
      `api/gallery/generated-template/${docId}?identity_token=${ID_Token}`
    );
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// to delete the generated template
export const deleteGeneratedTemplate = async (
  doc_id: string,
  ID_Token: string
) => {
  try {
    const response = await api.post(
      `api/gallery/delete-generated-template/?doc_id=${doc_id}&identity_token=${ID_Token}`
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// to fetch all the generated templates
export const getAllGeneratedTemplates = async (ID_Token: string) => {
  try {
    const response = await api.get(
      `/api/gallery/all-generated-template/?identity_token=${ID_Token}`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching generated templates:", error);
    throw error;
  }
};

// to get the  document

export const generateUrl = async (doc_id: string, type: string , ID_Token : any) => {
  try {
    const { data } = await api.get(
      `/api/generate-url/${doc_id}?identity_token=${ID_Token}&type=${type}`
    );
    return data;
  } catch (error) {
    console.error("Error fetching URL:", error);
    throw error;
  }
};
