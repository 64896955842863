import React from "react";
import { Modal } from "react-bootstrap";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";

import { ModalLazyLoadedImage } from "./ModalLazyLoadedImage";
import LoadingIndicator from "./LoadingIndicator";

interface DocumentModalProps {
    showModal: boolean;
    closeModal: () => void;
    deleteDocument: (docId: string) => void;
    handlePrev: () => void;
    handleNext: () => void;
    currentIndex: number;
    nestedArray: string[];
    docId: string;
    modalLoading: boolean;
    generateddocument: (docId: string) => void;
  }

const UploadedGeneratedModal: React.FC<DocumentModalProps> = ({
    showModal,
    closeModal,
    deleteDocument,
    handlePrev,
    handleNext,
    currentIndex,
    nestedArray,
    docId,
    modalLoading,
    generateddocument,
  }) => {
    return (
        <Modal
        show={showModal}
        onHide={closeModal}
        centered
        className="PreviewModal"
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <div className="button-container previewmodal-button">
            <div className="form-group text-center me-2">
              <button
                className="btn-danger py-2 mr-2"
                onClick={() => deleteDocument(docId)}
              >
                <RiDeleteBin5Line />
              </button>
              <span className="mx-2">
                <button
                  className="univ-btn-outline py-2"
                  onClick={() => generateddocument(docId)}
                >
                  Generated Documents
                </button>
              </span>
              {/* <DropdownButton
                  id="dropdown-basic-button"
                  title="Delete"
                >
                Delete
                </DropdownButton> */}
            </div>
            <button onClick={handlePrev} disabled={currentIndex === 0}>
              <MdKeyboardArrowLeft />
            </button>
            <button
              onClick={handleNext}
              disabled={currentIndex === nestedArray.length - 1}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="previewmodal p-0">
          {modalLoading ? (
            <div className="loading pt-5 mx-5">
              <div className="spinner-box mb-3 mx-5">
                Loading
                <div className="pulse-container ms-3">
                  <div className="pulse-bubble pulse-bubble-1"></div>
                  <div className="pulse-bubble pulse-bubble-2"></div>
                  <div className="pulse-bubble pulse-bubble-3"></div>
                </div>
              </div>
            </div>
          ) : (
            <div className="authLogin mx-5 mt-5 mb-5">
              <ul className="file-list mb-0 ">
                {nestedArray.map((item, index) => (
                  <li className="file-item" key={index}>
                    {index === currentIndex && (
                      <>
                        <ModalLazyLoadedImage
                          src={item}
                          alt={`Image ${index}`}
                        />
                      </>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Modal.Body>
      </Modal>
  );
};

export default UploadedGeneratedModal;
