import React, { useState } from "react";
import Robo from "../../../assets/images/robo.png";
import { Link } from "react-router-dom";
import LogInModal from "../../../components/LogInModal";
import { getAuthToken } from "../../../utils/token.utils";

function App() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const token = getAuthToken();
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-5">
              <div className="worksImage">
                <img src={Robo} alt="" />
              </div>
            </div>
            <div className="col-xl-6">
              <div className="worksContent">
                <h3>
                  How <span className="brand-color">Valmiki</span> Works
                </h3>
                <p>
                  Conversational AI, which includes technologies like chatbots,
                  virtual assistants, and advanced language models, works by
                  simulating human-like conversations through the use of natural
                  language processing and machine learning techniques. The
                  process begins with the collection of vast amounts of text
                  data, including written conversations, books, articles, and
                  more. This data is used to train machine learning models, such
                  as neural networks, to understand and generate human language.
                </p>
                <ul>
                  <li>
                    Upload your custom documents in doc, pdf format or
                    presentation.
                  </li>
                  <li>Chat with me specifying what do you want to create.</li>
                  <li>Select recommended templates or choose your own.</li>
                  <li>Provide necessary values.</li>
                  <li>Sit back and wait.</li>
                  <li>
                    Preview the document/ pptx, edit it if necessary and finally
                    download.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
