import axios from "axios";
import { API_URL } from "./api";
import { getAuthToken } from "./token.utils";

import { refreshAccessToken, checkTokenExpiry } from "./refreshtoken.utils";

//  an axios instance with a base URL and default headers
const api = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

let isRefreshing = false;
let failedRequestsQueue: ((newToken: string) => void)[] = [];

// timer to check token expiry
const tokenExpiryCheckInterval = 10000;
setInterval(checkTokenExpiry, tokenExpiryCheckInterval);

api.interceptors.request.use(
  async (config) => {
    const token = getAuthToken();
    config.headers.Authorization = `Bearer ${token}`;
    await checkTokenExpiry();
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  async (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      // This is done for now to manually check for error response to know if refresh token has expired
      if (
        error.response.data.detail === "Session expired. Please log in again."
      ) {
        localStorage.clear();
        window.location.href = "/";
      }
      originalRequest._retry = true;

      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const { newToken, newidtoken } = await refreshAccessToken();
          isRefreshing = false;

          localStorage.setItem(`token`, String(newToken));
          localStorage.setItem(`idtoken`, String(newidtoken));

          originalRequest.headers.Authorization = `Bearer ${newToken}`;
          return api(originalRequest);
        } catch (refreshError) {
          isRefreshing = false;
          localStorage.clear();
          window.location.href = "/";
          throw refreshError;
        }
      } else {
        return new Promise((resolve) => {
          failedRequestsQueue.push((newToken: string) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            resolve(api(originalRequest));
          });
        });
      }
    }
    return Promise.reject(error);
  }
);

export default api;
