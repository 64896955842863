import { NavLink, useLocation } from "react-router-dom";

import $ from "jquery";
import { FaBars, FaTimes } from "react-icons/fa";
import { useEffect } from "react";
import Logo from "../assets/images/vallogo.svg";
import { BiSolidPlusCircle } from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import Swal from "sweetalert2";

const Sidebar = ({
  conversation,

  handleNewChat,
  loading,
  generatingloading,
  finalgeneratingloading,
}: {
  conversation: any;
  handleNewChat: () => void;

  loading: boolean;
  generatingloading: boolean;
  finalgeneratingloading: any;
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    $(".open-btn").on("click", function () {
      $(".sidebar").addClass("active");
    });

    $(".close-btn").on("click", function () {
      $(".sidebar").removeClass("active");
    });
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };

  const handleLogoClick = (event: any) => {
    event.preventDefault();
    navigate("/");
  };

  // console.log(conversation);

  const firstMessagesMap: { [key: string]: React.ReactNode } =
    conversation.reduce((acc: any, item: any) => {
      if (!acc[item.id]) {
        acc[item.id] = item.content;
      }
      return acc;
    }, {} as { [key: string]: React.ReactNode });

  const firstMessageId = Object.keys(firstMessagesMap)[0];
  const firstMessageContent = firstMessagesMap[firstMessageId];

  // console.log(firstMessageContent);

  const handleUserClick =
    (chatId: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault();

      navigate(`/chat/${chatId}`);
    };

  const handleNewChatClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Starting new chat will delete your previous conversation. You won't be able to revert this!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#1c6176",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      focusConfirm: false, // Prevent autofocus on the "Yes" button
      focusCancel: false, // Prevent autofocus on the cancel button
      customClass: {
        popup: "my-swal-popup",
        title: "my-swal-title",
        icon: "my-swal-icon",
        htmlContainer: "my-swal-html-container",
      },
    }).then((result: any) => {
      if (result.isConfirmed) {
        handleNewChat();
      }
    });
  };

  const params = useParams();

  const MessageHeadercount = Object.keys(firstMessagesMap).length;

  return (
    <>
      <div className="text-end open-btn pt-3 togglebtn">
        <button className="univ-btn p-3 py-2">
          <FaBars />
        </button>
      </div>

      <div className="sidebar shadow-sm round" id="side_nav">
        <button className="btn d-lg-none .d-xl-block d-md-non d-block close-btn px-2 py-1">
          <FaTimes color="#FFFFF" fill="#FFFFFF" />
        </button>
        <div className="mx-3 mt-0  mb-4 ">
          <a href="/" onClick={handleLogoClick}>
            <img src={Logo} alt="logo" width={150} className="py-2" />
          </a>

          {/* <div
            className="addnewBtn d-flex align-items-center justify-content-between"
            title="New Chat"
            onClick={
              loading || generatingloading ? undefined : handleNewChatClick
            }
            data-tooltip-content={
              loading || generatingloading
                ? "Just a moment, we're processing your request."
                : ""
            }
          >
            <span>Start New Chat</span>

            <BiSolidPlusCircle color="#FFFFF" fill="#4D8B9E" size={35} />
          </div> */}

          <div
            className="addnewBtn d-flex align-items-center justify-content-between"
            title="If you click new chat , your previous conversation history will be deleted."
            onClick={
              MessageHeadercount > 0 &&
              !loading &&
              !generatingloading &&
              !finalgeneratingloading
                ? handleNewChatClick
                : undefined
            }
            data-tooltip-content={
              loading || generatingloading || finalgeneratingloading
                ? "Just a moment, we're processing your request."
                : ""
            }
          >
            <span>Start New Chat</span>

            <BiSolidPlusCircle color="#FFFFF" fill="#4D8B9E" size={35} />
          </div>
        </div>
        <div className="list-sidebar flex-grow">
          <div className="mx-3 mb-1 title-topic">Today</div>
          {/* {MessageHeadercount > 1
            ? Object.entries(firstMessagesMap).map(([id, content]) => (
                <div key={id}>
                  <NavLink
                    data-tooltip-id={id === params.chatId ? "" : "my-tooltip"} // Conditionally render tooltip
                    to={`/chat/${id}`}
                    className={`text-decoration-none sidebr ${
                      loading || generatingloading ? "disabled" : ""
                    }`}
                    onClick={
                      loading || generatingloading
                        ? undefined
                        : handleUserClick(id)
                    }
                    data-tooltip-content={
                      loading || generatingloading
                        ? "Just a moment, we're processing your request."
                        : ""
                    }
                  >
                    {content}
                  </NavLink>
                  <Tooltip id="my-tooltip" />
                </div>
              ))
            : Object.entries(firstMessagesMap).map(([id, content]) => (
                <div key={id}>
                  <NavLink
                    to=""
                    className="text-decoration-none sidebrcontent "
                  >
                    {content}
                  </NavLink>
                </div>
              ))} */}
          {firstMessageContent && (
            <div>
              <NavLink to="" className="text-decoration-none sidebrcontent ">
                {firstMessageContent}
              </NavLink>
            </div>
          )}
        </div>

        <button
          className="logout-button text-white py-2 mt-auto mx-3 mb-3"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </>
  );
};

export default Sidebar;
