import api from "../../utils/axiosInstance";

export const fetchCategories = async () => {
  try {
    const response = await api.get("/api/get_category/");
    return response.data;
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

export const addCategory = async (newCategory: string) => {
  try {
    await api.post(`/api/upload_category/?doc_category=${newCategory}`);
  } catch (error: any) {
    console.error("Error adding category:", error.response.data.detail);
    throw error;
  }
};
