import React from "react";
import SupportICon from "../../../assets/images/support.png";
import AccuracyIcon from "../../../assets/images/accuracy.png";
import CreationICon from "../../../assets/images/content-creator.png";

function App() {
  return (
    <>
      <section className="py-5">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title text-center">
                <h2>Features and Benefits</h2>
              </div>
            </div>
            <div className="col-xl-4 mt-5">
              <div className="feature-block text-center">
                <img src={SupportICon} alt="" />
                <h3>Enhanced Customer Support</h3>
                <p>
                  AI can be integrated into chatbots and virtual assistants,
                  improving the efficiency and availability of customer support
                </p>
              </div>
            </div>
            <div className="col-xl-4 mt-5">
              <div className="feature-block text-center">
                <img src={AccuracyIcon} alt="" />
                <h3>Compliance and Accuracy</h3>
                <p>
                  AI can be integrated into chatbots and virtual assistants,
                  improving the efficiency and availability of customer support
                </p>
              </div>
            </div>
            <div className="col-xl-4 mt-5">
              <div className="feature-block text-center">
                <img src={CreationICon} alt="" />
                <h3>Content Creation and Curation</h3>
                <p>
                  AI can be integrated into chatbots and virtual assistants,
                  improving the efficiency and availability of customer support
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default App;
