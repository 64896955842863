import React, { useState, useEffect } from "react";
import UploadModal from "../../../components/UploadModal";
import api from "../../../utils/axiosInstance";
import Loading from "../../../components/Loading";
import { ConfirmationModal } from "./TemplatechangeConfirmationDialog";
import { BsZoomIn } from "react-icons/bs";
import { FileListProps } from "./interface";
import PreviewModal from "./PreviewModal";
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; 

export const FileType = ({
  loading,
  fileTypeResponse,
  setLoading,
  setUserParameters,
  setIsuerParameters,
  setFinaluserParameters,
  setprevUserParameters,
  handleUserParameter,
  setGeneratedHtmlLink,
  setHtmlApiCallCompleted,
  setGeneratedLink,
  setUserQueries
}: FileListProps) => {
  const [selectedItemIndex, setSelectedItemIndex] = useState<string | null>(
    null
  );
  const [selectedItemClass, setSelectedItemClass] = useState<string>("");

  const [showConfirmationDialog, setShowConfirmationDialog] =
    useState<boolean>(false);
  const [selectedItemID, setSelectedItemID] = useState<string | null>(null);
  const [startIndex, setStartIndex] = useState(0);
  const [showDoc, setDocShow] = useState(false);
  const [apicalledonce, setApiCalledOnce] = useState<boolean>(false);

  const ID_Token = localStorage.getItem("idtoken");

  const sendLinksToGeneration = async (links: string[]) => {
    setLoading(true);
    setIsuerParameters(true);
    try {
      const response = await api.post(
        `/api/generation/get_user_parameters/?identity_token=${ID_Token}`,
        { links }
      );
      const userParameters: string[] = response.data.response;
      setUserParameters(response.data.response);
      setFinaluserParameters(response.data.response);
      if (userParameters.length > 0) {
        setUserParameters(userParameters);
        setprevUserParameters(userParameters.length);
        handleUserParameter(userParameters);
      }
    } catch (error) {
      console.error("Error:", error);

      setLoading(false);
    } finally {
      setLoading(false);
      // setApiCallInProgress(false);
    }

    try {
      const response = ["test"];
      setGeneratedHtmlLink(response);
    } catch (error) {
      console.error(error);
    } finally {
      setHtmlApiCallCompleted(true);
    }
  };
  const handleImageClick = async (index: string, id: string) => {
    setDocShow(true);
    setSelectedItemID(id);
    setSelectedItemIndex(index);
  };

  const handleItemClick = async (id: string, index: string) => {
    setApiCalledOnce(true);
    if (showConfirmationDialog) {
      setDocShow(true);
      setSelectedItemIndex(index);
    }

    setSelectedItemIndex(selectedItemIndex === index ? null : index);
    setSelectedItemClass(selectedItemIndex === index ? "" : "abc");
    setUserQueries({}); // Empty object


    try {
      const response = await api.get(
        `/api/chat/file/?doc_id=${index}&identity_token=${ID_Token}`
      );
   

      const links = response.data
        // .map((link: string) => link.trim())
        // .filter((link: string) => link !== "");
      setGeneratedLink(links);
      await sendLinksToGeneration(links);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false)
      Swal.fire({
        // title: "Document Deleted",
        text: "This document has been deleted from database. Please choose another document.",
        icon: "info",
        confirmButtonColor: "#1c6176", 
        confirmButtonText: "OK", 
        customClass: {
            popup: 'my-swal-popup',
            title: 'my-swal-title',
            icon: 'my-swal-icon',
            htmlContainer: 'my-swal-html-container',
        },
    });
    
    }
  };

  const handleNext = () => {
    if (startIndex + 5 < fileTypeResponse.data.length) {
      setStartIndex(startIndex + 5);
    }
  };

  const handlePrev = () => {
    if (startIndex - 5 >= 0) {
      setStartIndex(startIndex - 5);
    }
  };

  const handleConfirmSwitchTemplate = async (id: string | null) => {
    setSelectedItemIndex(null);
    setSelectedItemClass("");

    if (selectedItemIndex !== null && id !== null) {
      setShowConfirmationDialog(false);
      await handleItemClick(id, selectedItemIndex);
    }
    setShowConfirmationDialog((prevState) => false);
  };

  const handleCancelSwitchTemplate = () => {
    setShowConfirmationDialog(false);
  };

  const handleclose = () => {
    setDocShow(false);
  };

  return (
    <div className="message file-type mb-3">
      {showConfirmationDialog && (
        <ConfirmationModal
          onConfirm={() => handleConfirmSwitchTemplate(selectedItemIndex)}
          onCancel={handleCancelSwitchTemplate}
          showDoc={showDoc}
          setDocShow={setDocShow}
        />
      )}

      {showDoc && (
        <PreviewModal
          setDocShow={setDocShow}
          showConfirmationDialog={showConfirmationDialog}
          setShowConfirmationDialog={setShowConfirmationDialog}
          apicalledonce={apicalledonce}
          id={selectedItemID} // Pass the selected item ID
          index={selectedItemIndex} // Pass the selected item index
          show={showDoc}
          onclose={handleclose}
          showDoc={showDoc}
          fileTypeResponse={fileTypeResponse.data}
          handleItemClick={handleItemClick}
          handleConfirmSwitchTemplate={handleConfirmSwitchTemplate}
          handleCancelSwitchTemplate={handleCancelSwitchTemplate}
          selectedItemIndex={selectedItemIndex}
          setLoading={setLoading}
        />
      )}

      <ul className="file-list">
        {typeof fileTypeResponse.data === "string" ? (
          <div className={`message valmiki-bot mx-5`}>
            {fileTypeResponse.data}
          </div>
        ) : (
          <>
            {fileTypeResponse.data
              .slice(startIndex, startIndex + 5)
              .map((file: any, index: any) => (
                <li key={index} className="file-item">
                  <div
                    className="fileDetail"
                    onClick={() => handleImageClick(file.doc_id, file.id)}
                  >
                    <BsZoomIn />
                    <div style={{ textDecoration: "none" }}>
                      {file.doc_title}
                    </div>
                  </div>
                  <a className={selectedItemClass}>
                    <img src={file.preview} alt="" />
                  </a>
                </li>
              ))}
         
          </>
        )}
      </ul>
      <ul className="file-list align-item-right">
  {fileTypeResponse.data.length > 5 && (
    <li className="text-center addDocIcon">
      <div className="pagination">
        <button
           className="univ-btn-outline mx-3"
          onClick={handlePrev}
          disabled={startIndex === 0}
          style={startIndex === 0 ? { cursor: "not-allowed" } : {}}
        >
          Previous
        </button>
        <button
          className="univ-btn-outline mx-3"
          onClick={handleNext}
          disabled={startIndex + 5 >= fileTypeResponse.data.length}
          style={startIndex + 5 >= fileTypeResponse.data.length ? { cursor: "not-allowed" } : {}}
        >
          Next
        </button>
      </div>
    </li>
  )}
</ul>

    </div>
  );
};
