import { Modal, DropdownButton, Dropdown } from "react-bootstrap";
import { useState, useEffect } from "react";
import React from "react";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import Swal from "sweetalert2";
import api from "../../../utils/axiosInstance";
import { AxiosError } from "axios";
import { toast } from "react-toastify";

interface previewgeneratedmodalprops {
  nestedArray: string[];
  show: boolean;
  pdflink: string | string[];
  doclink: string | string[];
  setShowDoc: React.Dispatch<React.SetStateAction<boolean>>;
  fileName: string;

  generateddocumentloading: boolean;
  setGeneratingdocumentLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setDocLink: React.Dispatch<React.SetStateAction<string | string[]>>;
  setPdfLink: React.Dispatch<React.SetStateAction<string | string[]>>;
  setNestedArray: React.Dispatch<React.SetStateAction<string[]>>;
}

interface ApiResponse {
  detail: string;
  // other properties if any
}
const PreviewGeneratedModal = ({
  show,
  pdflink,
  doclink,
  nestedArray,
  setShowDoc,
  fileName,
  setDocLink,
  setPdfLink,
  generateddocumentloading,
  setGeneratingdocumentLoading,
  setNestedArray,
}: previewgeneratedmodalprops) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showOptions, setShowOptions] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setGeneratingdocumentLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [generateddocumentloading]);

  useEffect(() => {
    const loadedImagesCount =
      document.querySelectorAll(".file-item img").length;
    if (loadedImagesCount === nestedArray.length) {
      setImagesLoaded(true);
    }
  }, [nestedArray]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const handleDownload = (format: string) => {
    if (format === "pdf") {
      window.open(pdflink as string, "_blank");
    } else if (format === "doc") {
      window.open(doclink as string, "_blank");
    }
    setShowOptions(false);
  };

  const handleImageLoadStart = () => {
    const loadedImagesCount =
      document.querySelectorAll(".file-item img").length;
    if (loadedImagesCount === nestedArray.length) {
      setGeneratingdocumentLoading(false);
      setImagesLoaded(true);
    }
  };

  const ID_Token = localStorage.getItem("idtoken");
  const handleclose = async () => {
    const result = await Swal.fire({
      title: "Save for Later?",
      html: `Would you like to access your generated document later? If so, please click "<b>Save</b>." 
      If not, please click "<b>Skip</b>." `,
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Skip",
      confirmButtonColor: "#dc3545", // Red color for the confirm button
      cancelButtonText: "Save",
    });

    if (result.isConfirmed) {
      try {
        const queryparams = {
          presigned_link: nestedArray,
          filename: fileName,
        };
        // const response = await api.post(
        //   // Added 'await' here
        //   `api/remove-generated-file/?identity_token=${ID_Token}`,
        //   queryparams // Removed extra 'queryparams' argument
        // );
        // if (response) {
        // Show success message with SweetAlert
        // Swal.fire(
        //   "Deleted!",
        //   "Your document has been deleted.",
        //   "success"
        // ).then(() => {
        //   // Close modal and navigate after SweetAlert confirmation
        //   setShowDoc(false);
        // });
        // } else {
        //   throw new Error("Failed to delete document");
        // }
        toast.success("Your generated document has been skipped .", {
          autoClose: 2000,
        });
        setShowDoc(false);
        setShowDoc(false);
        setDocLink("");
        setPdfLink("");
        setNestedArray([]);
      } catch (error) {
        // Added 'error' parameter to catch block
        console.log(error); // Display error in console
      }
    } else if (result.dismiss === Swal.DismissReason.cancel) {
      try {
        console.log("file_link", doclink);
        console.log("pdflink", pdflink);
        const queryparams = {
          file_link: doclink != null ? doclink : null,
          pdf_link: pdflink,
          presigned_link: nestedArray,
          filename: fileName,
        };

        const response = await api.post(
          // Added 'await' here
          `api/save-generated-file/?identity_token=${ID_Token}`,
          queryparams // Removed extra 'queryparams' argument
        );

        if (response) {
          // // Show success message with SweetAlert
          // Swal.fire(
          //   "Saved!",
          //   "Your document has been saved in dynamodb.",
          //   "success"
          // ).then(() => {
          //   // Close modal and navigate after SweetAlert confirmation
          //   setShowDoc(false);
          // });
          toast.success(
            "Your generated document has been saved successfully .",
            { autoClose: 2000 }
          );
          setShowDoc(false);
        } else {
          throw new Error("Failed to saved document");
        }
      } catch (error: unknown) {
        if (error instanceof Error && "response" in error) {
          const axiosError = error as AxiosError; // Cast 'error' to AxiosError
          if (
            axiosError.response &&
            axiosError.response.data &&
            typeof axiosError.response.data === "object" && // Narrow down the type to object
            "detail" in axiosError.response.data
          ) {
            const responseData = axiosError.response.data as ApiResponse; // Type assertion
            console.log(responseData.detail);
            Swal.fire({
              title: "Failed!",
              text: responseData.detail,
              icon: "error",
            }).then(() => {
              setShowDoc(false);
              setDocLink("");
              setPdfLink("");
              setNestedArray([]);
            });
          } else {
            // Handle case where 'response' or 'detail' is missing
            console.log("Error response or detail is missing");
          }
        } else {
          // Handle other types of errors
          console.log("Non-Axios error occurred");
        }
      }
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleclose}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <div className="button-container previewmodal-button">
            <div className="form-group text-center me-2">
              <DropdownButton id="dropdown-basic-button" title="Download File">
                {pdflink ? (
                  <>
                    <Dropdown.Item onClick={() => handleDownload("doc")}>
                      Download as Doc
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDownload("pdf")}>
                      Download as PDF
                    </Dropdown.Item>
                  </>
                ) : (
                  <Dropdown.Item onClick={() => handleDownload("doc")}>
                    Download as PPT
                  </Dropdown.Item>
                )}
              </DropdownButton>
            </div>
            <button onClick={handlePrev} disabled={currentIndex === 0}>
              <MdKeyboardArrowLeft />
            </button>
            <button
              onClick={handleNext}
              disabled={currentIndex === nestedArray.length - 1}
            >
              <MdKeyboardArrowRight />
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="previewmodal modal-height">
          <div className="authLogin">
            <ul className="file-list mb-0">
              {generateddocumentloading && !imagesLoaded ? (
                <div className="loading mb-3 mx-5">
                  <div className="spinner-box mb-3 mx-5 mt-5">
                    Just a moment, please.
                    <div className="pulse-container ms-3">
                      <div className="pulse-bubble pulse-bubble-1"></div>
                      <div className="pulse-bubble pulse-bubble-2"></div>
                      <div className="pulse-bubble pulse-bubble-3"></div>
                    </div>
                  </div>
                </div>
              ) : (
                nestedArray.map((item, index) => (
                  <li className="file-item" key={index}>
                    {index === currentIndex && (
                      <img
                        src={item}
                        alt=""
                        className="full-width"
                        onLoad={handleImageLoadStart}
                      />
                    )}
                  </li>
                ))
              )}
            </ul>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default PreviewGeneratedModal;
