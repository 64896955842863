

/**
 * Component for displaying the user's chat icon.
 * Retrieves user information using a token and displays the user's initial letter as a profile image.
 */

interface chaticonprops{
  initialLetter : string
}
export const UserChatIcon = ({initialLetter}:chaticonprops) => {

  return (
    <div className="d-flex align-items-center chatBlock mb-1 ">
      <div className="chaticon-head">
        <div className="sidebar-img">
          <div className="rounded-circle profile-img profile-sidebar">
            {initialLetter}
          </div>
        </div>
      </div>
      <h6 className="">You</h6>
    </div>
  );
};

/**
 * Component for displaying the system's chat icon.
 * Displays a predefined letter "V" as the system's profile image.
 */

// SystemChatIcon component
export const SystemChatIcon = () => {
  return (
    <div className="d-flex align-items-center chatBlock mb-1 ">
      <div className="chaticon-head">
        <div className="sidebar-img">
          <div className="rounded-circle profile-img profile-sidebar">v</div>
        </div>
      </div>
      <h6 className="">Valmiki</h6>
    </div>
  );
};
