import React from 'react';
import { IoArrowForwardOutline } from "react-icons/io5";

/**
 * UploadButton component displays a button for uploading files or proceeding to the next step.
 * It adjusts its appearance and behavior based on the uploading status and selected files.
 * 
 * Props:
 * - uploading: Boolean indicating whether files are currently being uploaded
 * - selectedFiles: Array of selected files
 * - selectedTemplate: Selected template category
 * - handleNextClick: Function to handle the click event for the next step
 */

interface UploadButtonProps {
  uploading: boolean;
  selectedFiles: File[];
  selectedTemplate: string;
  handleNextClick: () => void;
}

const UploadButton: React.FC<UploadButtonProps> = ({ uploading, selectedFiles, selectedTemplate, handleNextClick }) => {
  // Determine if the next button should be disabled based on selected files and template
  const isNextButtonDisabled = !selectedFiles.length || !selectedTemplate;

  return (
    <button
      className={`univ-btn${isNextButtonDisabled ? "" : " hover-enabled"}`}
      onClick={handleNextClick}
      disabled={isNextButtonDisabled || uploading}
      style={isNextButtonDisabled ? { cursor: "not-allowed" } : {}}
    >
      {uploading ? "Uploading" : "Next"} <IoArrowForwardOutline />
    </button>
  );
};

export default UploadButton;
