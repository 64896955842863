import React from 'react';

interface ErrorAlertProps {
  error: string;
}

const ErrorAlert: React.FC<ErrorAlertProps> = ({ error }) => {
  return (
    <div className="alert alert-danger alert-dismissible fade show mb-3" role="alert">
      {error}
    </div>
  );
};

export default ErrorAlert;
