import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";

const GoogleCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthToken = async () => {
      try {
        const session = await Auth.currentSession();
        const accessToken = session.getAccessToken();
        const refreshToken = session.getRefreshToken();
        const idtoken = session.getIdToken();
        if (accessToken) {
          localStorage.setItem("token", accessToken.getJwtToken());
          localStorage.setItem("refreshtoken", refreshToken.getToken());
          localStorage.setItem("idtoken", idtoken.getJwtToken());
          navigate("/");
        } else {
          console.log("No access token found");
        }
      } catch (error) {
        console.error("Error fetching current session:", error);
        navigate("/");
      }
    };

    fetchAuthToken();
  }, [navigate]);

  return null;
};

export default GoogleCallback;
