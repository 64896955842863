// import React, { useState, ChangeEvent } from "react";

// /**
//  * SelectFileType component provides a dropdown to select template categories.
//  * It triggers the onTemplateChange callback when a category is selected.
//  *
//  * Props:
//  * - onTemplateChange: Callback function triggered when a template category is selected
//  */

// interface Props {
//   onTemplateChange: (template: string) => void;
// }

// const SelectFileType: React.FC<Props> = ({ onTemplateChange }) => {
//   const [selectedTemplateCategory, setSelectedTemplateCategory] = useState("");

//   /**
//    * Handles the change event when a template category is selected.
//    * Updates the selected template category state and triggers the onTemplateChange callback.
//    * @param event - Change event
//    */

//   const handleTemplateCategoryChange = (
//     event: ChangeEvent<HTMLSelectElement>
//   ) => {
//     const category = event.target.value;
//     setSelectedTemplateCategory(category);
//     onTemplateChange(category); // Trigger the callback with the selected category
//   };

//   return (
//     <select
//       className="univ-input"
//       value={selectedTemplateCategory}
//       onChange={handleTemplateCategoryChange}
//     >
//       <option value="">Select Template Categories</option>
//       <option value="JD">JD</option>
//       <option value="CV">CV</option>
//       <option value="Other">Other</option>
//     </select>
//   );
// };

// export default SelectFileType;

import React, { useState, useEffect } from "react";
import Select, { components } from "react-select";
import api from "../../utils/axiosInstance";
import { toast } from "react-toastify";
import { fetchCategories, addCategory } from "./services";

/**
 * SelectFileType component provides a dropdown to select template categories.
 * It triggers the onTemplateChange callback when a category is selected.
 *
 * Props:
 * - onTemplateChange: Callback function triggered when a template category is selected
 */

interface Props {
  onTemplateChange: (template: string) => void;
}

const SelectFileType: React.FC<Props> = ({ onTemplateChange }) => {
  const [categories, setCategories] = useState<string[]>([]);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [newCategoryInput, setNewCategoryInput] = useState<string>("");

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const categories = await fetchCategories();
      setCategories(categories);
    } catch (error) {
      toast.error("Failed to fetch categories. Please try again later.");
    }
  };

  const handleAddCategory = async () => {
    const newCategory = newCategoryInput.trim();
    if (newCategory) {
      try {
        await addCategory(newCategory);
        setCategories((prevCategories) => [...prevCategories, newCategory]);
        setSelectedOption({ value: newCategory, label: newCategory });
        onTemplateChange(newCategory);
        setNewCategoryInput("");
        toast.success("Category added successfully!");
      } catch (error) {
        toast.error("Failed to add category. Please try again later.");
      }
    }
  };

  const handleInputChange = (inputValue: string) => {
    setNewCategoryInput(inputValue);
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      width: "100%",
      fontSize: "14px",
      border: "1px solid #ddd",
      borderRadius: "6px",
      padding: "8px 15px",
      outline: "none",
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#5dd1f5" : null,
      color: "#333",
      padding: "10px 15px",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: state.isFocused && !state.data.isAddOption ? "#f0f0f0" : null, // Apply background color only if not hovering over the "Add" option
      },
    }),
  };
  

  const options = categories.map((category) => ({
    value: category,
    label: category,
  }));

  const ClearIndicator = (props: any) => {
    return null;
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        {/* Custom dropdown indicator if needed */}
      </components.DropdownIndicator>
    );
  };

  const customComponents = {
    ClearIndicator,
    DropdownIndicator,
  };

  const handleChange = (selectedOption: any) => {
    if (selectedOption && selectedOption.isAddOption) {
      handleAddCategory();
    } else if (selectedOption) {
      setSelectedOption(selectedOption);
      onTemplateChange(selectedOption.value);
    }
  };

  const formatOptionLabel = ({
    label,
    isAddOption,
  }: {
    label: string;
    isAddOption?: boolean;
  }) => {
    if (isAddOption) {
      return (
        <button
          className="univ-btn"
          style={{ width: "100%", textAlign: "center" }}
        >
          Add
        </button>
      );
    }
    return label;
  };

  const modifiedOptions = [
    ...options,
    { value: newCategoryInput, label: newCategoryInput, isAddOption: true },
  ];

  return (
    <div>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={modifiedOptions}
        styles={customStyles}
        placeholder="Select Template Categories"
        isSearchable
        onInputChange={handleInputChange}
        formatOptionLabel={formatOptionLabel}
        isClearable
        backspaceRemovesValue
        components={customComponents}
      />
    </div>
  );
};

export default SelectFileType;
