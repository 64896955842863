import React from 'react';
import Router from './routes/router'
import { Amplify} from "aws-amplify";
import awsExports from "./configs/aws";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChatProvider } from './context/ChatContext/chatcontext';

function App() {
  Amplify.configure(awsExports);
  return (
    <>
    <ChatProvider>
      <ToastContainer />
      <Router />
    </ChatProvider>

    </>
  );
}

export default App;
