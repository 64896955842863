import axios from "axios";
// Define fetchUserInfo function
export const fetchUserInfo = async (token: string) => {
  try {
    const response = await axios.get(
      `https://${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/userInfo`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error fetching user info:", error);
    throw error;
  }
};
