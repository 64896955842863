import React from "react";
import { BsArrowUpCircleFill } from "react-icons/bs";
import { FaSpinner } from "react-icons/fa";
import { PromptProps } from "./interface";

const PromptInputBox = ({
  userQuery,
  setUserQuery,
  handleKeyDown,
  isuserparameters,
  loading,
  userParameters,
  generatingloading,
  finalgeneratingloading,
  handleUserParameter,
  handleSendQuery,
  isregenerating,
  iscontinuegenerating
}: PromptProps) => {
  return (
    <div className="wrapper">
      <textarea
        placeholder="Write your text and tell the AI to read it to you"
        value={userQuery}
        onChange={(e) => setUserQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        disabled={loading || generatingloading || finalgeneratingloading} 
        required
      />
      <button
        onClick={isuserparameters ? () => handleUserParameter(userParameters) : handleSendQuery}
        disabled={loading || !userQuery.trim()}
      >
        {loading ||  isregenerating || iscontinuegenerating || generatingloading || finalgeneratingloading ? (
          <div className="spinner-container"><FaSpinner/></div>
        ) : (
          <BsArrowUpCircleFill fill="#1c6176" />
        )}
      </button>
    </div>
  );
};

export default PromptInputBox;
