import { useState } from "react";
import { Modal } from "react-bootstrap";
import axios, { AxiosResponse } from "axios";
import { API_URL } from "../utils/api";
import { getAuthToken } from "../utils/token.utils";
import { toast } from "react-toastify";
import UploadedFileProgressbar from "./UploadFile/UploadedFileProgressbar";
import SelectFileType from "./UploadFile/SelectFileType";
import Fileupload from "./UploadFile/Fileupload";
import UploadButton from "./UploadFile/UploadButton";
import ErrorAlert from "./ErrorAlert";
import GeneratingWebsocket from "./UploadFile/UploadGeneratingwebsocket";

/**
 * UploadModal component manages the modal for uploading documents.
 * It handles the selection of files, uploading process, and error handling.
 */

const UploadModal = () => {
  const [showDoc, setDocShow] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState<number[]>([]);
  const [uploading, setUploading] = useState(false);

  const token = getAuthToken();
  const [error, setError] = useState<string>("");
  const [totalfilecount, setTotalFileCount] = useState<number>(0);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [generatingdocument, setGeneratingdocument] = useState(false);

  /**
   * Handles the change of template selection.
   * @param template The selected template value.
   */
  const handleTemplateChange = (template: string) => {
    setSelectedTemplate(template);
  };

  /**
   * Handles the click event for the "Next" button.
   * Initiates the file upload process.
   */

  const ID_Token = localStorage.getItem("idtoken");
  const handleNextClick = () => {
    if (selectedFiles.length > 0 && selectedTemplate) {
      setUploading(true);

      const requestData = new FormData();

      selectedFiles.forEach((file) => {
        requestData.append(`files`, file);
      });

      toast.promise(
        axios
          .post(
            `${API_URL}/api/upload/file/?doc_type=${selectedTemplate}&identity_token=${ID_Token}`,
            requestData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                const { loaded, total } = progressEvent;
                const percentCompleted = Math.round(
                  (loaded * 100) / (total ?? 1)
                );

                setUploadProgress(
                  uploadProgress.map((progress, index) =>
                    index === index ? percentCompleted : progress
                  )
                );
              },
            }
          )
          .then((response: AxiosResponse) => {
            setGeneratingdocument(true);
        
            setTimeout(() => {
                setUploading(false);
                setDocShow(false);
                setSelectedFiles([]);
                setUploadProgress([]);
                // window.location.reload();
            }, 600);
        })
        
          .catch((error: Error) => {
            console.log(error);

            setTimeout(() => {
              setUploading(false);
              setDocShow(false);
            }, 1000);
            setGeneratingdocument(true);
          }),
        {
          pending: "Your file is being uploaded",
          success: "Your file has been successfully uploaded",
          error: "Failed to upload file",
        }
      );
    }
  };

  const handleclose = () => {
    setDocShow(false);
    setSelectedFiles([]);
    setError("");
    setTotalFileCount(0);
  };

  const handleUpdateGeneratingDocument = (status: boolean) => {
    setGeneratingdocument(status);
  };

  return (
    <div>
      <button className="univ-btn-outline" onClick={() => setDocShow(true)}>
        Upload Docs
      </button>

      <Modal show={showDoc} onHide={() => handleclose()} centered>
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body>
          <div className="docUpload">
            <GeneratingWebsocket
              generatingdocument={generatingdocument}
              totalfilecount={totalfilecount}
              onUpdateGeneratingDocument={handleUpdateGeneratingDocument}
            />
            <h2>Upload Template</h2>
            <Fileupload
              selectedFiles={selectedFiles}
              uploadProgress={uploadProgress}
              setTotalFileCount={setTotalFileCount}
              setUploadProgress={setUploadProgress}
              setError={setError}
              setSelectedFiles={setSelectedFiles}
            />
            {error && <ErrorAlert error={error} />}

            <div>
              <SelectFileType onTemplateChange={handleTemplateChange} />
              <UploadedFileProgressbar
                selectedFiles={selectedFiles}
                uploadProgress={uploadProgress}
                setSelectedFiles={setSelectedFiles}
                setUploadProgress={setUploadProgress}
                setError={setError}
                setTotalFileCount={setTotalFileCount}
              />
            </div>
            <div className="text-end mt-4">
              <UploadButton
                uploading={uploading}
                selectedFiles={selectedFiles}
                selectedTemplate={selectedTemplate}
                handleNextClick={handleNextClick}
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UploadModal;
