

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../../components/Header";
import { fetchGeneratedTemplate, generateUrl, deleteGeneratedTemplate } from  "./partials/apiservice"
import ModalComponent from "./partials/GeneratedModalComponent";
import DocumentCard from "./partials/DocumentCard";
import { FaArrowLeftLong } from "react-icons/fa6";

interface GeneratedDocument {
  preview: string;
  doc_id: string;
  doc_title: string;
  gallery_type: string;
}

const GeneratedDocument: React.FC = () => {
  const { docId } = useParams<{ docId?: string }>(); // Change here
  const actualDocId = docId || ""; // Provide a default empty string if docId is undefined

  const [generatedData, setGeneratedData] = useState<GeneratedDocument[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLoading, setModalLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [pdflink, setPdfLink] = useState<string | string[]>([]);
  const [doclink, setDocLink] = useState<string | string[]>([]);
  const [nestedArray, setNestedArray] = useState<string[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [deleteddocId, setDeletedDocId] = useState("");
  const [activeTab, setActiveTab] = useState(localStorage.getItem("activeTab") || "practice");
  const navigate = useNavigate();
  const ID_Token = localStorage.getItem("idtoken") ?? ""; 


  useEffect(() => {
    setLoading(true);
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = await fetchGeneratedTemplate(actualDocId, ID_Token); 
      setGeneratedData(data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handlegeneratedtemplatepreview = async (doc_id: string, gallery_type: string) => {
    setDeletedDocId(doc_id);
    setModalLoading(true);
    setShowModal(true);
    try {
      const data = await generateUrl( doc_id, gallery_type  , ID_Token);
      setDocLink(data.pdf);
      setPdfLink(data.doc);
      setNestedArray(data.image);
    } catch (error) {
      console.error("Error fetching URL:", error);
    } finally {
      setModalLoading(false);
    }
  };

  const deleteDocument = async () => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this document!',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      confirmButtonColor: '#dc3545',
      cancelButtonText: 'No, keep it'
    });

    if (result.isConfirmed) {
      try {
        await deleteGeneratedTemplate(deleteddocId, ID_Token);
        Swal.fire({
          title: "Deleted!",
          text: "Your document has been deleted.",
          icon: "success",
          confirmButtonColor: "#1c6176",
        }).then(() => {
          setLoading(true);
          fetchData();
          setShowModal(false);
          navigate(`/documents`);
        
        });
      } catch (error) {
        console.log(error);
        Swal.fire("Error!", "Failed to delete document. Please try again later.", "error");
      }
    }
  };

  const handleDownload = (format: string) => {
    if (format === "pdf") {
      window.open(pdflink as string, "_blank");
    } else if (format === "doc") {
      window.open(doclink as string, "_blank");
    }
  };

  const handleClose = () => {
    setSelectedImage(null);
    setShowModal(false);
    setNestedArray([]);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  return (
    <>
      <Header />
      <div className="container mt-5">
        <div className="bg-white profile-header round shadow-sm mb-4 mob-block py-4 px-4">
          <div className="content-1 round ms-4">
            <a href='/documents'   className="color-primary text-decoration-none"> <FaArrowLeftLong /><span className='mx-2 '>Back</span></a >
            <>
              <div className="tab-content" id="nav-tabContent">
                <div className={`tab-pane fade ${activeTab === "practice" ? "show active" : ""} pt-3`} id="nav-practice">
                  {loading ? (
                    <div>Loading...</div>
                  ) : generatedData.length > 0 ? (
                    <div className="row">
                      {generatedData.map((file, index) => (
                        <DocumentCard key={index} file={file} handleClick={handlegeneratedtemplatepreview} />
                      ))}
                    </div>
                  ) : (
                    <>
                      <div className="mb-3">
                        You dont have any Generated document available. You can save generated document while generating to access.
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      <ModalComponent
        showModal={showModal}
        handleClose={handleClose}
        handleDownload={handleDownload}
        handlePrev={handlePrev}
        handleNext={handleNext}
        currentIndex={currentIndex}
        nestedArray={nestedArray}
        modalLoading={modalLoading}
        deleteDocument={deleteDocument}
        pdflink = {pdflink}
        doclink = {doclink}
      />
    </>
  );
};

export default GeneratedDocument;


