/**
 * Fileupload component handles the uploading of files through drag and drop or file input.
 * It displays a file upload interface where users can drop files or select them using a file input.
 * Accepted file formats include .pdf, .docx, and .doc.
 *
 * Props:
 * - uploadProgress: Array containing upload progress for each file
 * - selectedFiles: Array containing the currently selected files
 * - setTotalFileCount: Function to update the total file count
 * - setUploadProgress: Function to update the upload progress for files
 * - setError: Function to set an error message
 * - setSelectedFiles: Function to update the selected files array
 */

import React, { useRef, ChangeEvent, SetStateAction, Dispatch } from "react";
import { BsCloudUpload } from "react-icons/bs";

interface FileuploadProps {
  uploadProgress: number[];
  selectedFiles: File[];
  setTotalFileCount: Dispatch<SetStateAction<number>>;
  setUploadProgress: Dispatch<SetStateAction<number[]>>;
  setError: Dispatch<SetStateAction<string>>;
  setSelectedFiles: Dispatch<SetStateAction<File[]>>;
}

const Fileupload: React.FC<FileuploadProps> = ({
  selectedFiles,
  uploadProgress,
  setTotalFileCount,
  setUploadProgress,
  setError,
  setSelectedFiles,
}) => {
  // Ref for file input element
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  /**
   * Handles the drag over event to prevent default behavior.
   * @param event - Drag event
   */
  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  /**
   * Handles the file change event when files are selected using the file input.
   * Adds selected files to the selectedFiles array and updates upload progress.
   * @param event - Change event
   */

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files);
      const totalFiles = selectedFiles.length + newFiles.length;

      if (totalFiles > 5) {
        setError("More than 5 files cannot be selected.");
      } else {
        const updatedFiles = [...selectedFiles];
        const updatedProgress = [...uploadProgress];
        newFiles.forEach((file) => {
          if (file.size > 2 * 1024 * 1024) {
            setError("Files larger than 2 MB cannot be uploaded.");
          } else {
            updatedFiles.push(file);
            updatedProgress.push(0);
            setError("");
          }
        });
        setSelectedFiles(updatedFiles);
        setTotalFileCount(updatedFiles.length);
        setUploadProgress(updatedProgress);
      }
    }
  };

  /**
   * Handles the drop event when files are dropped into the drop area.
   * Adds dropped files to the selectedFiles array and updates upload progress.
   * @param event - Drag event
   */

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const newFiles = Array.from(files);
      const totalFiles = selectedFiles.length + newFiles.length;

      if (totalFiles > 5) {
        setError("More than 5 files cannot be selected.");
      } else {
        newFiles.forEach((file) => {
          if (file.size > 2 * 1024 * 1024) {
            setError("Files larger than 2 MB cannot be uploaded.");
          }else if (!/\.pdf$|\.docx$|\.doc$|\.pptx$|\.ppt$/.test(file.name.toLowerCase())) {
            setError("Only .pdf, .doc, .docx, .ppt, and .pptx files are supported.");
           } else {
            setSelectedFiles((prevFiles) => {
              const updatedFiles = [...prevFiles, file];
              const validatedFiles = updatedFiles;
              const selectedFilesCount = validatedFiles.length;
              setTotalFileCount(selectedFilesCount);
              return updatedFiles;
            });

            setUploadProgress((prevProgress) => [...prevProgress, 0]);
            setError("");
          }
        });
      }
    }
  };

  return (
    <div
      className="uploadWrap text-center mb-4"
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <BsCloudUpload />
      <h3>Drag and drop your files here</h3>
      <p>File supported PDF, DOCX , PPT</p>
      <span>OR</span>
      <div className="uploadbtn">
        <label htmlFor="upload" className="file-upload__label">
          Browse Files
        </label>
        <input
          type="file"
          id="upload"
          accept=".docx, .pdf, .doc, .pptx, .ppt"
          className="file-upload__input"
          ref={fileInputRef}
          onChange={handleFileChange}
          multiple
        />
      </div>
    </div>
  );
};

export default Fileupload;
