import { ChatTitleProps } from "./interface";
import Logo from "../../../assets/images/valmiki-logo.svg";
const ChatTitle = ({
  conversationStarted,
  conversationlength,
  filteredChatHistory,
}: ChatTitleProps) => {
  // console.log("filteredChatHistory", filteredChatHistory);
  return (
    <div className="mb-4">
      {conversationlength <= 0 ? (
        <>
          <div>
            {" "}
            <h3 className="chat-title-text">
              Welcome to <span className="chat-title-valmiki">Valmiki!</span>
            </h3>
          </div>

          <p>
            Get started with new modern AI that helps you to simulate your text
            <br />
            to your favorite character and documents
          </p>
        </>
      ) : (
        <>
          <div className="chatlogo">
            {" "}
            {/* <h3 className="chat-title-text">
              <span className="chat-title-valmiki">Valmiki</span>
            </h3> */}
            {/* <img src={Logo} alt="" /> */}
          </div>
        </>
      )}
    </div>
  );
};

export default ChatTitle;
