import { FileTypeProps } from "./interface";

const FileListWithPagination = ({ fileTypeResponse }: FileTypeProps) => {
  return <div></div>;
};

// Exporting the component function
export const getFileListWithPaginationContent = (props: FileTypeProps) => {
  return <FileListWithPagination {...props} />;
};
