
import { Route, Routes } from 'react-router-dom'
 
import Login from '../pages/Auth/Login'
import LandingPage from '../pages/LandingPage/LandingPage'
import GoogleCallback from '../pages/Callback/callback';
import ProtectedRoute from './ProtectedRoute';
import Chat from '../pages/Chat/Chat';

import Templates from '../pages/Chat/Templates';
import Documents from '../pages/Documents/document';
import GeneratedDocument from '../pages/Documents/generateddocuments';
import UserConsentForm from '../pages/TermsCondition/termsCondition';
import PrivacyPolicyForm from '../pages/PrivacyPolicy/privacyPolicy';

function App() {
  return (
    <div>
        <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path = "/callback" element = {<GoogleCallback/>}/>
            <Route path="/login" element={<Login />} />
            <Route path = "/terms-and-conditions" element = {<UserConsentForm/>}/>
            <Route path = "/privacy-policy" element = {<PrivacyPolicyForm/>}/>
            
            <Route element={<ProtectedRoute />}> 
            <Route path ="/chat" element={<Chat/>} />
            <Route path = "/documents" element = {<Documents/>}/>
            <Route path = "/generateddocument/:docId" element = {<GeneratedDocument/>}/>
            <Route path="/chat/:chatId" element={<Chat />} />
            <Route path='/templates' element={<Templates/>}/></Route>
        </Routes>
    </div>
  );
}

export default App;
