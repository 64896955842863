// ModalImageLoader.tsx
import React from 'react';

interface ModalImageLoaderProps {
  currentIndex: number;
  nestedArray: string[];
}

const ModalImageLoader: React.FC<ModalImageLoaderProps> = ({ currentIndex, nestedArray }) => (
  <div className="authLogin mx-5 mt-5 mb-5">
    <ul className="file-list mb-0 ">
      {nestedArray.map((item, index) => (
        <li className="file-item" key={index}>
          {index === currentIndex && (
            // Render Image
            <img src={item} alt={`Image ${index}`} className="full-width" />
          )}
        </li>
      ))}
    </ul>
  </div>
);

export default ModalImageLoader;
