import React, { useEffect } from "react";
import Header from "../../components/Header";
const PrivacyPolicyForm = () => {
    useEffect(()=> {
        window.scrollTo(0,0);
    }, []);
    return (
        <>
        <Header />
        <div className="container mt-5">
            <div className="bg-white profile-header round shadow-sm mb-4 mob-block py-4 px-4">
                <div className="content-1 round ms-4">
                    <h2>Privacy Policy</h2>
                </div>
                <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>Information We Collect</h6>
                We gather personal information from you in the following ways:
                <ul>
                    <li><strong>Personal Information:</strong> When you register an account or 
                    interact with us, we collect details such as your name, email address, 
                    phone number, and other contact information.</li>
                    <li><strong>User Content:</strong> As you use our services, we may collect 
                    personal data included in any input, file uploads, or feedback you provide.</li>
                    <li><strong>Account Details:</strong> Upon creating an account, we collect 
                    information tied to your account, including your name, contact information, 
                    and other necessary details for account management.</li>
                    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar
                    technologies to manage and enhance our services. Cookies are small data files sent 
                    to your browser. You can control cookie settings through your browser, but disabling 
                    cookies may affect your ability to use certain features of our services.</li>
                    <li><strong>Additional Information:</strong> We may also collect extra information you 
                    provide through surveys, events, or other interactions to establish your identity or 
                    improve our services.</li>
                </ul>
              </p>

                <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>Uses of Your Information</h6>
                We use your personal information in various ways, including:
                <ul>
                    <li><strong>Service Management and Enhancement:</strong> To provide, manage, and improve 
                    our services, including conducting research.</li>
                    <li><strong>Communication:</strong> To keep you informed about our services, events, and updates.</li>
                    <li><strong>Program Development:</strong> To create and enhance new programs and services.</li>
                    <li><strong>Security and Fraud Prevention:</strong> To prevent fraud, address criminal activity, 
                    and safeguard our IT systems and networks.</li>
                    <li><strong>Business Transactions:</strong> To manage business transactions, including 
                    mergers or acquisitions.</li>
                    <li><strong>Legal Compliance:</strong> To meet legal requirements, protect our rights 
                    and property, and uphold the safety of you and others.</li>
                </ul>
              </p>

                <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>Data Masking and Aggregation</h6>
                We may anonymize your personal information to prevent it from being linked back to you. 
                This anonymized data may be used for analyzing service effectiveness, improving features,
                and conducting research. Aggregated data may be shared with third parties, published, 
                or made available publicly. We commit to maintaining de-identified information 
                anonymously and will not attempt to re-identify it, except as legally required.
              </p>

                <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>Your Rights</h6>
                Depending on your location, you may have certain rights regarding your personal information, including:
                <ul>
                    <li><strong>Access:</strong> The right to view your personal information and understand how it is being used.</li>
                    <li><strong>Deletion:</strong> The right to request removal of your personal information from our records.</li>
                    <li><strong>Correction:</strong> The right to update or correct inaccurate personal information.</li>
                    <li><strong>Portability:</strong> The right to receive your personal information in a structured, 
                    commonly used format and transfer it to another organization.</li>
                    <li><strong>Restriction:</strong> The right to limit how we process your personal information.</li>
                    <li><strong>Withdrawal of Consent:</strong> The right to withdraw consent for processing your personal 
                    information where consent is the basis for processing.</li>
                    <li><strong>Objection:</strong> The right to object to certain types of data processing.</li>
                    <li><strong>Complaint:</strong> The right to file a complaint with your local data protection authority.</li>
                </ul>
              </p>

              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>Children’s Privacy</h6>
                Our services are not intended for children under 12. We do not knowingly collect 
                information from children under 12. If you believe we have collected such 
                information, please contact us to rectify the issue. For users between 12 and 17, 
                parental consent is required to use our services.
              </p>

              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>Security and Data Retention</h6>
                We take reasonable steps to protect your personal information from unauthorized 
                access, loss, or misuse. However, no online transmission is entirely secure, 
                and we cannot guarantee complete security. You should exercise caution when 
                sending sensitive information.
                <br />
                We will retain your personal information only as long as necessary to provide 
                our services, resolve disputes, ensure safety, and comply with legal obligations. 
                The retention period depends on various factors, including the nature of the 
                information and applicable legal requirements.
              </p>

              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>Policy Updates</h6>
                We may revise this Privacy Policy periodically. Updates will be posted on this page, 
                and in some cases, additional notice may be provided as required by law.
              </p>

              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>International Data Transfers</h6>
                If your personal information is transferred to or processed in countries outside 
                of your home country, we will ensure that appropriate safeguards are in place 
                to protect your information in accordance with this Privacy Policy and applicable 
                laws.
              </p>

              <p
                className="card-text"
                style={{
                  color: "black",
                  lineHeight: "1.75",
                  fontSize: "0.875rem",
                }}
              >
                <h6>Third-Party Links</h6>
                Our services may contain links to third-party websites. We are not responsible 
                for the privacy practices or content of those sites. We encourage you to review 
                the privacy policies of any third-party sites you visit.
              </p>

            </div>
        </div>
        </>
    )
};

export default PrivacyPolicyForm;