import React, { useState, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { renderToString } from "react-dom/server";
import ReactMarkdown from "react-markdown";
import Quill from "quill"; // Import Quill module
import htmlToMd from "html-to-md"; // Import html-to-md library
import { Modal } from "react-bootstrap";
import { ConversationItem } from "../Chat/partials/interface";
import { toast } from "react-toastify";
import "./../../assets/css/markdowncontent.css";
import Swal from "sweetalert2";

interface MarkdownEditContent {
  key: any;
  ref: any;
  props: {
    children: string;
  };
}
interface markdownprops {
  showEditModal: boolean;
  markdowneditcontent: MarkdownEditContent;
  setMarkdownEditContent: React.Dispatch<
    React.SetStateAction<MarkdownEditContent>
  >;
  setShowEditModal: React.Dispatch<React.SetStateAction<boolean>>;
  conversation: ConversationItem[];
  setConversation: React.Dispatch<React.SetStateAction<ConversationItem[]>>;
}

const Link = Quill.import("formats/link");

// class MyLink extends Link {
//   static create(value: any) {
//     let node = super.create(value);
//     value = this.sanitize(value);
//     if (!value.startsWith("http")) {
//       value = "http://" + value;
//     }
//     node.setAttribute("href", value);
//     return node;
//   }
// }

class MyLink extends Link {
  static create(value: any) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (!value.startsWith("http")) {
      value = "https://" + value;
    }
    if (value.endsWith("/")) {
      value = value.slice(0, -1); // Remove the trailing "/"
    }

    node.setAttribute("href", value);
    node.setAttribute("target", "_blank");

    return node;
  }
}

Quill.register(MyLink);

function MarkdownToQuill({
  conversation,
  setConversation,
  showEditModal,
  markdowneditcontent,
  setMarkdownEditContent,
  setShowEditModal,
}: markdownprops) {
  const quillRef = useRef<ReactQuill>(null);

  const [markdownContent, setMarkdownContent] = useState<string>(
    markdowneditcontent.props.children
  );
  const [isContentEmpty, setIsContentEmpty] = useState<boolean>(false);

  // Function to check if content is empty
  useEffect(() => {
    setIsContentEmpty(markdownContent.trim() === "");
  }, [markdownContent]);

  // Function to convert HTML to Markdown
  const htmlToMarkdown = (html: string) => {
    return htmlToMd(html);
  };

  const deltaToMarkdown = (delta: any) => {
    const quill = new Quill(document.createElement("div"));
    quill.setContents(delta);
    const html = quill.root.innerHTML;
    return htmlToMarkdown(html);
  };

  //   const handleSave = () => {
  //     if (quillRef.current) {
  //       const quill = quillRef.current.getEditor();
  //       const delta = quill.getContents();
  //       const editedMarkdown = deltaToMarkdown(delta);

  //       console.log("Edited Markdown:", editedMarkdown);
  //       localStorage.setItem("markdowncontent", editedMarkdown);
  //       setShowEditModal(false)
  //       setMarkdownEditContent(editedMarkdown)

  //     }
  //   };

  const handleSave = () => {
    if (quillRef.current) {
      const quill = quillRef.current.getEditor();
      const delta = quill.getContents();
      const markdownContent = deltaToMarkdown(delta);

      if (!markdownContent.trim()) {
        Swal.fire({
          icon: "error",

          text: "Content cannot be empty!",
          confirmButtonColor: "#1c6176",
        });
        return;
      }
      localStorage.setItem("markdowncontent", markdownContent);

      // Accessing the latest conversation item
      const latestItemIndex = conversation.length - 1;
      const latestItem = conversation[latestItemIndex];

      // Checking if the content is a React element and setting props.children accordingly
      if (latestItem && typeof latestItem.content === "object") {
        const updatedContent = {
          ...latestItem.content,
          props: {
            ...latestItem.content.props,
            children: markdownContent,
          },
        };

        // Creating a new conversation item with updated content
        const updatedItem = {
          ...latestItem,
          content: updatedContent,
        };

        // Updating the conversation with the edited content
        const updatedConversation = [...conversation];
        updatedConversation[latestItemIndex] = updatedItem;
        setConversation(updatedConversation);
        toast.success("Your content has been saved!");
      }
      setShowEditModal(false);
    }
  };
  const renderLink = (props: any) => {
    return <a {...props} target="_blank" rel="noopener noreferrer" />;
  };

  const markdownToHTML = (markdown: string) => {
    return renderToString(
      <ReactMarkdown children={markdown} components={{ a: renderLink }} />
    );
  };

  useEffect(() => {
    if (quillRef.current) {
      const formattedHTML: string = markdownToHTML(markdownContent);

      // Initialize a Quill instance to use its clipboard module
      const quill = quillRef.current.getEditor();
      const delta = quill.clipboard.convert(formattedHTML); // Convert HTML to Delta object
      quill.setContents(delta); // Set the contents using Delta object
    }
  }, [markdownContent]);
  const handleclose = async () => {
    setShowEditModal(false);
  };
  return (
    <Modal
      show={showEditModal}
      onHide={handleclose}
      centered
      dialogClassName="custom-MarkEdit-modal" // Keep your existing class
      className="specific-modal"
      //   style={{ maxWidth: '1000px', width: '200%' }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="EditModal modal-height">
        <div>
          <ReactQuill ref={quillRef} />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="button-container previewmodal-button">
          <div className="form-group text-center me-2">
            <span className="mx-2">
              <button
                className="univ-btn py-2"
                onClick={handleSave}
                disabled={isContentEmpty}
                style={isContentEmpty ? { cursor: "not-allowed" } : {}}
              >
                Save
              </button>
            </span>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default MarkdownToQuill;
