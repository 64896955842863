/**
 * GeneratingWebsocket component responsible for handling WebSocket connections and data fetching.
 * It interacts with the WebSocket server to send data and displays toast notifications.
 *
 * Props:
 * - generatingdocument: Indicates whether documents are being generated.
 * - totalfilecount: The total count of files.
 * - onUpdateGeneratingDocument: Function to update the generatingdocument state.
 */

import React, { useState, useEffect } from "react";
import api from "../../utils/axiosInstance";
import { toast } from "react-toastify";
import { API_URL } from "../../utils/api";

interface WebsocketProps {
  generatingdocument: boolean;
  totalfilecount: number | undefined;
  onUpdateGeneratingDocument: (status: boolean) => void;
}

interface ResponseData {
  status: boolean;
  message: {
    username: string;
  };
}

const GeneratingWebsocket: React.FC<WebsocketProps> = ({
  generatingdocument,
  totalfilecount,
  onUpdateGeneratingDocument,
}) => {
  const [ws, setWs] = useState<WebSocket | null>(null);
  const [username, setUsername] = useState<string | null>(
    localStorage.getItem(
      `CognitoIdentityServiceProvider.${process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`
    ) || ""
  );

  useEffect(() => {
    setUsername(
      localStorage.getItem(
        `CognitoIdentityServiceProvider.${process.env.REACT_APP_AUTH_USER_POOL_WEB_CLIENT_ID}.LastAuthUser`
      )
    );
  }, []);

  useEffect(() => {
    const socket = new WebSocket(
      `wss://${process.env.REACT_APP_API_URL}/upload/ws`
    );

    socket.onopen = () => {
      console.log("Connected to WebSocket server");
    };

    socket.onmessage = (event) => {
      onUpdateGeneratingDocument(false);

      if (event.data) {
        toast.update("generating-toast", {
          render: event.data,
          type: "success",
          autoClose: 5000,
          isLoading: false,
          closeButton: true,
        });
        if (socket) {
          socket.close();
          console.log("WebSocket connection closed");
        }
      }
    };

    setWs(socket);

    return () => {
      // Cleanup function to close WebSocket when component unmounts
    };
  }, []);

  useEffect(() => {
    if (generatingdocument) {
      toast.loading("Document processing in progress", {
        autoClose: false,
        toastId: "generating-toast",
      });
      if (ws && totalfilecount && ws.readyState === WebSocket.OPEN) {
        const messageinput = {
          text: totalfilecount,
          user_id: username,
        };
        const message = JSON.stringify(messageinput);
        ws.send(message);
      }
    }
  }, [generatingdocument, totalfilecount, ws]);

  return null;
};

export default GeneratingWebsocket;
